import { useContext, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import EmailForm from "../../components/email-form";
import { MEDIUM, MOBILE, TAB } from "../../constants/breakpoints";
import { PublicContext } from "../../context/PublicContext";
import { UserContext } from "../../context/UserContext";
import { getDeviceInfoForTrackEvent } from "../../event-utils/dropchat-event-track";
import DropChatLogo from "../../images/dropchat-logo.svg";
import HandIcon from "../../images/hand-icon.svg";
import OrLInes from "../../images/oricon.svg";
import { magic } from "../../magic";
import { setAuthHeader } from "../../myaxios";
import GoogleLoginButton from "../../utils/google-login-button";
// import GitHubLoginButton from "../../utils/github-login-button";
const MainSection = styled(Container)`
  flex-direction: column;
  padding: 2rem;
  @media (max-width: ${MOBILE.maxWidth}px) {
    box-shadow: none;
    padding: 0.4rem;
    border-radius: 0px;
    background: transparent;
  }
`;

// const ParagraphSection = styled.p`
//   color: #33303c;
//   font-size: 30px;
//   font-weight: 700;
//   line-height: 40px;
//   // text-align: center;
//   & span {
//     color: #1585b5;
//   }
// `;

const CenterContentRow = styled(Row)`
  margin-bottom: 0.2rem;
  margin-top: 0.3rem;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const CenterContentColumn = styled(Col)`
  justify-content: center;
  align-items: center;
  & span {
    @media (max-width: ${MOBILE.maxWidth}px) {
      font-size: 12px;
    }
    @media (min-width: ${TAB.minWidth}px) {
      font-size: 12px;
    }
    @media (min-width: ${MEDIUM.minWidth}px) {
      font-size: 14px;
    }
  }
`;

const MessageContent = styled.p`
  color: #ea5455;
  font-weight: 700;
  background: #ffe1e1;
  border-radius: 6px;
  padding: 5px 10px;
  @media (max-width: ${MOBILE.maxWidth}px) {
    font-size: 10px;
  }
  @media (min-width: ${TAB.minWidth}px) {
    font-size: 10px;
  }
  @media (min-width: ${MEDIUM.minWidth}px) {
    font-size: 14px;
  }
`;
const DivForOrLine = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 5px 0px;
`;
const OrlinesImgTag = styled.img`
  width: 64%;
`;
const DivForLoginWithCredintial = styled.div`
  display: flex;
  justify-content: center;
  cursor: pointer;
  margin-top: 1%;
  flex-direction: column;
`;

const ButtonForCred = styled.button`
  width: 100%;
  height: 50px;
  background: #ffffff;
  border: 1px solid #1585b5;
  border-radius: 3px;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  justify-content: center;
  text-align: center;
  display: flex;
  align-items: center;
  color: #1585b5;
  cursor: pointer;
  z-index: 11111;

  @media (max-width: ${MOBILE.maxWidth}px) {
    font-size: 16px;
    height: 50px;
    width: 100%;
  }
  @media (min-width: ${TAB.minWidth}px) {
    font-size: 16px;
    height: 30px;
    width: 100%;
  }
  @media (min-width: ${MEDIUM.minWidth}px) {
    font-size: 16px;
    height: 50px;
  }
`;

const MagicLinkLogin = ({ loadingState, showLoginPopup }: any) => {
  const { setUser } = useContext(UserContext);

  const { ShowConditionalLoginPage, setShowConditionalLoginPage } =
    useContext(PublicContext);
  const navigate = useNavigate();
  const [disabled, setDisabled] = useState(false);
  const [error, setError] = useState("");

  const getUserDataFromServer = async (didToken: string) => {
    // console.log("login getUserDataFromServer, ", didToken);
    const res = await fetch(`${process.env.REACT_APP_SERVER_URL}/login`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        token: didToken,
        deviceInfo: await getDeviceInfoForTrackEvent(),
      }),
    });

    // console.log("res is, ", res);

    if (res.status === 200) {
      const data = await res.json();
      // Set the UserContext to the now logged in user
      let userMetadata = await magic.user.getMetadata();
      // console.log("data is, ", data);
      // console.log("userMetaData is, ", userMetadata);
      setUser({ ...data });

      // console.log("user is in login, ", user);

      if (userMetadata) {
        loadingState(false);
        navigate("/my-chatbots/create-pdf-chatbot");
      }

      if (data.jwt) setAuthHeader(data.jwt);

      return true;
    }

    return false;
  };

  async function handleLoginWithEmail(email: string) {
    // console.log("handle login", browser.tabs.getCurrent());
    if (!email) {
      setError("Please enter email");
      return;
    }
    try {
      setDisabled(true); // disable login button to prevent multiple emails from being triggered

      // Trigger Magic link to be sent to user

      localStorage.setItem("verifyCredentialRequired", "yes");

      const didToken = await magic.auth.loginWithMagicLink({
        email,
        showUI: true,
        redirectURI: `${process.env.REACT_APP_FRONTEND_URL}/callback`,
      });

      // console.log("login didToken is, ", didToken);

      if (didToken) {
        getUserDataFromServer(didToken).then((res) => {});
      }
      // Validate didToken with server
    } catch (error) {
      setDisabled(false); // re-enable login button - user may have requested to edit their email
    }
  }
  // const today = new Date();
  return (
    <MainSection fluid>
      <CenterContentRow>
        <CenterContentColumn>
          <img src={DropChatLogo} alt="logo" />
        </CenterContentColumn>
      </CenterContentRow>
      <CenterContentRow>
        <CenterContentColumn>
          Welcome to Dropchat &nbsp;
          <img src={HandIcon} alt="logo" />
        </CenterContentColumn>
      </CenterContentRow>

      <CenterContentRow>
        <CenterContentColumn>
          <EmailForm
            disabled={disabled}
            onEmailSubmit={handleLoginWithEmail}
            error={error}
          />
        </CenterContentColumn>
      </CenterContentRow>
      {showLoginPopup && (
        <CenterContentRow>
          <CenterContentColumn>
            <MessageContent>
              You've hit the maximum number of questions that can be asked
              without logging in. But don't worry, you can keep chatting by
              logging into your account.
            </MessageContent>
          </CenterContentColumn>
        </CenterContentRow>
      )}
      {process.env.REACT_APP_ENV === "production" ? (
        <>
          <DivForOrLine>
            <OrlinesImgTag src={OrLInes} alt="or" />
          </DivForOrLine>
          <DivForLoginWithCredintial>
            <ButtonForCred
              onClick={() => {
                setShowConditionalLoginPage({
                  ...ShowConditionalLoginPage,
                  toShowMagicLinkloginPage: false,
                  toShowForgetPassPage: false,
                });
              }}
            >
              Login With Credentials
            </ButtonForCred>
            <GoogleLoginButton />
            {/* <GitHubLoginButton /> */}
          </DivForLoginWithCredintial>
        </>
      ) : (
        <>
          <DivForOrLine>
            <OrlinesImgTag src={OrLInes} alt="or" />
          </DivForOrLine>
          <DivForLoginWithCredintial>
            <GoogleLoginButton />
            {/* <GitHubLoginButton /> */}
          </DivForLoginWithCredintial>
        </>
      )}
    </MainSection>
  );
};

export default MagicLinkLogin;
