import axios from "axios";
import { Field, Formik } from "formik";
import { useContext, useState } from "react";
import { Container, Row } from "react-bootstrap";
import { NotificationManager } from "react-notifications";
// import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import {
  EventCategory,
  SubCategory,
  UserEvents,
} from "../../event-utils/dropchat-event-enums";
import { trackEvent } from "../../event-utils/track-event";
import HidePassEye from "../../images/hidepasseye.svg";
import Spinner from "../../images/loader.gif";
import OrLInes from "../../images/oricon.svg";
import ShowPassEye from "../../images/showpasseye.svg";
import { isTestUser, mixPanel, parseErrorResponse } from "../../utils/utils";
import { MOBILE } from "../../constants/mobile";
import { PublicContext } from "../../context/PublicContext";
import GoogleLoginButton from "../../utils/google-login-button";
// import GitHubLoginButton from "../../utils/github-login-button";

const DivForLoginWithCredintial = styled.div`
  display: flex;
  justify-content: center;
  cursor: pointer;
  margin-top: 3%;
  flex-direction: column;
`;
const ButtonForCred = styled.button`
  width: 100%;
  height: 50px;
  background: #ffffff;
  border: 1px solid #1585b5;
  border-radius: 3px;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  justify-content: center;
  text-align: center;
  display: flex;
  align-items: center;
  color: #1585b5;
  cursor: pointer;
  z-index: 11111;
`;
const BottomDiv = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 10px;
`;
const SpanAccount = styled.span`
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #1585b5;
`;
const SpanSignup = styled.span`
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: #1585b5;
  margin-left: 7px;
  cursor: pointer;
`;

const DIV = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const DivForPara = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
const LoginPara = styled.span`
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  color: #1585b5;
`;

const SuccessMessage = styled.span`
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  color: #1585b5;
  text-align: center;
  margin: 1rem;
`;

const LoginSignupFieldsDiv = styled.div`
  /* padding-top: 10px; */
  display: flex;
  justify-content: center;
  flex-direction: column;
`;
const Input = styled(Field)`
  width: 100%;
  background: #ffffff;
  border: 1px solid rgba(51, 48, 60, 0.6);
  border-radius: 4px;
  padding: 0.7rem;
  margin-bottom: 0.5rem;

  @media (max-width: ${MOBILE.maxWidth}px) {
    border-radius: 4px;
  }
`;
const DivForLoginButton = styled.div`
  padding: 10px 0;
  width: 100%;
  text-align: center;
  margin-bottom: 10px;
`;
const ShowPasswordImgTag = styled.img`
  position: absolute;
  top: 30%;
  right: 2%;
  z-index: 1;
`;
const ShowConfimrPasswordImgTag = styled.img`
  position: absolute;
  top: 30%;
  right: 2%;
  z-index: 1;
`;
const LoginButton = styled.button`
  width: 100%;
  height: 50px;
  color: white;
  background: #1585b5;
  border-radius: 3px;
  border: none;
  cursor: pointer;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #ffffff;
  &:hover {
    background: #094159bd;
    border: none;
  }
`;
const SpanErrorLoginSignup = styled.span`
  font-style: normal;
  font-weight: 500;
  font-size: 11px;
  line-height: 12px;
  color: #df5c04;
  width: 100%;
  display: flex;
`;

const SpinnerLoginImg = styled.img`
  height: 35px;
  margin-left: 15px;
`;

const DivForPassShowHide = styled.div`
  position: relative;
  width: 100%;
  text-align: center;
`;

const DivForOrLine = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;
const OrlinesImgTag = styled.img`
  width: 64%;
`;
const Label = styled.label`
  font-weight: 300;
  font-size: 14px;
  color: #33303c;
  margin-bottom: 2px;
`;
const SignupRenewed = () => {
  // const navigate = useNavigate();
  const [toShowPasswordSignup, setToShowPasswordSignup] =
    useState<boolean>(false);
  const { ShowConditionalLoginPage, setShowConditionalLoginPage } =
    useContext(PublicContext);
  const [toShowPasswordConfirmSignup, setToShowPasswordConfimSignup] =
    useState<boolean>(false);
  const [signupLoader, setSignupLoader] = useState(false);

  const [showSuccessMessage, setShowSuccessMessage] = useState<boolean>(false);

  const signUp = (values: any, resetForm: any) => {
    setSignupLoader(true);
    axios
      .post(`${process.env.REACT_APP_SERVER_URL}/auth/local/register`, {
        email: values.email,
        password: values.password,
      })
      .then((response: any) => {
        if (response.data && response.data.success) {
          trackEvent({
            email: values.email,
            eventCategory: EventCategory.USER,
            event: UserEvents.USER_SIGNUP_USING_FORM,
            subCategory: SubCategory.EVENT,
            rawData: response.data,
          });
          if (!isTestUser(response.data.email))
            mixPanel(response.data.email, "Signed Up", "Signup Form");
          setSignupLoader(false);
          setShowSuccessMessage(true);
          NotificationManager.success(response.data.message);
        } else {
          trackEvent({
            email: values.email,
            eventCategory: EventCategory.USER,
            event: UserEvents.USER_SIGNUP_FAILED_USING_FORM,
            subCategory: SubCategory.EVENT,
            rawData: response.data,
          });
          setSignupLoader(false);
          NotificationManager.error(
            response.data.message
              ? response.data.message
              : "Unable to process your request, please try later"
          );
        }

        setSignupLoader(false);
      })
      .catch((error: any) => {
        setSignupLoader(false);
        // console.error("error", error);
        NotificationManager.error(parseErrorResponse(error));
      });
  };

  const toShowPasswordInPlainText = () => {
    var x: any = document.getElementById("myInput");
    if (x.type === "password") {
      x.type = "text";
    } else {
      x.type = "password";
    }
  };

  const toShowPasswordInPlainTextConfirmBox = () => {
    var x: any = document.getElementById("myInputId");
    if (x.type === "password") {
      x.type = "text";
    } else {
      x.type = "password";
    }
  };

  return (
    <>
      <Container>
        {!showSuccessMessage ? (
          <Row>
            <DIV>
              <DivForPara>
                <LoginPara>Signup</LoginPara>
              </DivForPara>
            </DIV>

            <Formik
              initialValues={{
                email: "",
                password: "",
                confirmPassword: "",
              }}
              validateOnChange
              validate={(values: any) => {
                const errors: {
                  email?: string;
                  password?: string;
                  confirmPassword?: string;
                } = {};

                // console.log("values -- ", values);

                if (!values.email) {
                  errors.email = " email required";
                } else if (
                  !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
                ) {
                  errors.email = "Invalid email address";
                }

                if (!values.password) {
                  errors.password = "password required";
                } else if (values.password.length <= 5) {
                  errors.password = "minimum 6 characters required";
                }
                if (!values.confirmPassword) {
                  errors.confirmPassword = " confirm password required ";
                }
                if (values.password !== values.confirmPassword) {
                  errors.confirmPassword = "confrim password not matched ";
                }

                // console.log("Errors --- ", errors);

                return errors;
              }}
              onSubmit={(values, { setSubmitting, resetForm }) => {
                trackEvent({
                  email: values.email,
                  eventCategory: EventCategory.USER,
                  event: UserEvents.USER_TRY_TO_SIGNUP_USING_FORM,
                  subCategory: SubCategory.EVENT,
                  rawData: values,
                });
                signUp(values, resetForm);
              }}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
              }) => (
                <form onSubmit={handleSubmit}>
                  <LoginSignupFieldsDiv>
                    <Label>Email Address</Label>
                    <Input
                      type="email"
                      name="email"
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {errors.email && (
                      <SpanErrorLoginSignup>
                        {errors.email && touched.email && errors.email}
                      </SpanErrorLoginSignup>
                    )}
                    <Label>Password</Label>
                    <DivForPassShowHide>
                      <ShowPasswordImgTag
                        src={toShowPasswordSignup ? ShowPassEye : HidePassEye}
                        onClick={() => {
                          toShowPasswordInPlainText();
                          setToShowPasswordSignup(!toShowPasswordSignup);
                        }}
                      />
                      <Input
                        type="password"
                        id="myInput"
                        name="password"
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />{" "}
                    </DivForPassShowHide>
                    {errors.password && (
                      <SpanErrorLoginSignup>
                        {errors.password && touched.password && errors.password}
                      </SpanErrorLoginSignup>
                    )}
                    <Label>Confirm Password</Label>
                    <DivForPassShowHide>
                      <ShowConfimrPasswordImgTag
                        src={
                          toShowPasswordConfirmSignup
                            ? ShowPassEye
                            : HidePassEye
                        }
                        onClick={() => {
                          toShowPasswordInPlainTextConfirmBox();
                          setToShowPasswordConfimSignup(
                            !toShowPasswordConfirmSignup
                          );
                        }}
                      />
                      <Input
                        type="password"
                        id="myInputId"
                        name="confirmPassword"
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    </DivForPassShowHide>
                    {errors.confirmPassword && (
                      <SpanErrorLoginSignup>
                        {errors.confirmPassword}
                      </SpanErrorLoginSignup>
                    )}
                    <DivForLoginButton>
                      <LoginButton type="submit">
                        SIGNUP
                        {signupLoader && (
                          <SpinnerLoginImg src={Spinner} alt="Loading" />
                        )}
                      </LoginButton>
                    </DivForLoginButton>
                  </LoginSignupFieldsDiv>
                </form>
              )}
            </Formik>
            <DivForOrLine>
              <OrlinesImgTag src={OrLInes} alt="or" />
            </DivForOrLine>

            <DivForLoginWithCredintial>
              <ButtonForCred
                onClick={() => {
                  setShowConditionalLoginPage({
                    ...ShowConditionalLoginPage,
                    toShowMagicLinkloginPage: true,
                    toShowSignupPage: false,
                  });
                }}
              >
                Login With Magic Link
              </ButtonForCred>
              <GoogleLoginButton />
              {/* <GitHubLoginButton /> */}
            </DivForLoginWithCredintial>

            <BottomDiv>
              <SpanAccount>Already have an account |</SpanAccount>
              <SpanSignup
                onClick={() => {
                  setShowConditionalLoginPage({
                    ...ShowConditionalLoginPage,
                    toShowMagicLinkloginPage: false,
                    toShowSignupPage: false,
                    toShowForgetPassPage: false,
                  });
                }}
              >
                LOGIN
              </SpanSignup>
            </BottomDiv>
          </Row>
        ) : (
          <DivForPara>
            <SuccessMessage>
              An email has been sent to registered email with further
              instructions
            </SuccessMessage>
          </DivForPara>
        )}
      </Container>
    </>
  );
};

export default SignupRenewed;
