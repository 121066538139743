import axios from "axios";
import { useContext, useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import {
  // DEVICE_MOBILE,
  MEDIUM,
  MOBILE,
  TAB,
} from "../../constants/breakpoints";
import { PublicContext } from "../../context/PublicContext";
// import DropChatPlateFormSvg from "../../images/dropchat-plateform.svg";
import LoaderImage from "../../images/landingAssets/cube-loader.gif";
import PDFSign from "../../images/pdf-ic.svg";
import PdfUrl from "../../images/pdf-url.svg";
import PdfYoutubeUrl from "../../images/pdf-youtube-url.svg";
import PdfYoutube from "../../images/pdf-youtube.svg";
import URLSign from "../../images/url-ic.svg";
import YouTubeSign from "../../images/youtube-ic.svg";
import YoutubeUrl from "../../images/youtube-url.svg";
import { Mixpanel } from "../../mixpanel";
import { Book } from "../../types";
import CreateBook from "./create-book";
import socket from "../../dropchat-socket";

const MainSectionParent = styled(Container)`
  flex-direction: column;
  padding: 0rem 0rem;
`;

const HeadingSection = styled.p`
  color: #33303c;
  font-size: 21px;
  font-weight: 500;
  margin-bottom: 0;
  color: rgba(51, 48, 60, 1);
`;

const DecsriptionSection = styled.p`
  color: #33303c;
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 0;
`;

const Item = styled(Col)`
  padding: 10px;
  background: ${(props: BoxBackground) => props.background};
  /* width: 100%; */
  height: 213px;
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.05);
  display: flex;
  flex-direction: column;
  margin: 10px;
  /* margin: 10px auto; */

  & a {
    text-decoration: none;
    flex: 1;
  }
  @media (max-width: ${MOBILE.maxWidth}px) {
    width: 40%;
    height: 260px;
  }
`;

const FirstInnerParent = styled.div`
  flex: 1;
  padding: 0;
  flex-direction: column;
  @media (max-width: ${MOBILE.maxWidth}px) {
    flex-direction: column;
    padding: 4px;
  }
  @media (min-width: ${TAB.minWidth}px) {
    flex-direction: column;
  }
  @media (min-width: ${MEDIUM.minWidth}px) {
    flex-direction: column;
  }
  min-height: 200px;
`;

interface BoxBackground {
  background: string;
}

const ParentDefaultBook = styled.div`
  background: rgba(229, 237, 237, 1);
  width: 100%;
  height: calc(100vh - 98px);
  flex-direction: column;
  /* padding: 10px; */
  padding: 10px 10px 10px 25px;
  @media (max-width: ${MOBILE.maxWidth}px) {
    margin-top: 30px;
  }
`;

const ImageDiv = styled.div`
  width: 100%;
  height: 150px;
  @media (max-width: ${MOBILE.maxWidth}px) {
    width: 100%;
    height: 214px;
  }
`;

const PlateFormImageDiv = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
`;
const TitleSpan = styled.span`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-top: 22px;
  font-size: 14px;
  color: rgba(51, 48, 60, 1);
  @media (max-width: ${MOBILE.maxWidth}px) {
    margin-top: 0px;
  }
`;

const LoaderForWaiting = styled.img`
  @media (max-width: ${MOBILE.maxWidth}px) {
    width: 30%;
  }
`;
const WaitingDescription = styled.h2`
  color: #33303c;
  font-size: 16px;
  line-height: 1.5;
  padding: 0rem 3rem;
`;

const WaitingTitle = styled.h2`
  color: #ec2d2d;
  font-size: 26px;
  line-height: 2;
  padding: 0rem 3rem;
`;

const RightColumn = styled.div`
  flex-direction: column;
  text-align: center;
  justify-content: center;
  align-items: center;
`;
const TryAnotherChatBot = styled.span`
  font-size: 16px;
  font-family: "Poppins", sans-serif;
  color: #1585b5;
  cursor: pointer;
  text-decoration: underline;
`;
const MainRow = styled(Row)`
  overflow: scroll;
  width: 100%;
  /* margin: 0; */
  @media (max-width: ${MOBILE.maxWidth}px) {
    justify-content: space-between;
  }
`;
const ImageTag = styled.img`
  min-height: 100%;
  min-width: 100%;
  object-fit: contain;
`;

const BookFunctionalityTrialPage = () => {
  const { indexingProgress, setIndexingProgress } =
    useContext(PublicContext);
  const [publicBooks, setPublicBooks] = useState<Book[]>([]);
  const [featureChatbotHeading, setFeatureChatbotHeading] =
    useState<string>("");
  const [featureChatbotSubHeading, setFeatureChatbotSubHeading] =
    useState<string>("");
  const [bookId, setBookId] = useState<any>();
  const navigate = useNavigate();

  // eslint-disable-next-line
  const [colors, setColors] = useState({
    firstBox: "rgba(226, 241, 255, 1)",
    secondBox: "rgba(234, 226, 255, 1)",
    thirdBox: "rgba(253, 226, 255, 1)",
    fourthBox: "rgba(255, 226, 226, 1)",
    commonBox: "rgba(226, 241, 255, 1)",
  });

  useEffect(() => {
    axios
      .get(
        process.env.REACT_APP_SERVER_URL +
          "/books/chatbots/active-feature-chatbots"
      )
      .then((response) => {
        if (response.data) {
          setPublicBooks(response.data.data);
        }
      })
      .catch((error) => {
        // handle error
      });
  }, []);

  useEffect(() => {
    axios
      .get(process.env.REACT_APP_SERVER_URL + "/public/getFeatureChatbotTitles")
      .then((response) => {
        if (response.data) {
          setFeatureChatbotHeading(response.data.featureChatbotHeading);
          setFeatureChatbotSubHeading(response.data.featureChatbotSubHeading);
        }
      })
      .catch((error) => {
        // handle error
      });
  }, []);

  const bookImageURL = (book: Book) => {
    if (book.imageUrl && book.imageUrl.length > 0) return book.imageUrl;

    let imageUrl = book.imageUrl ? book.imageUrl : PDFSign;

    let isPdf = false;
    let isUrl = false;
    let isYT = false;

    if (!(book.chatType === "chatgpt" || book.type === "existing")) {
      if (book.sources && book.sources.length <= 0) {
        if (book.type === "aws") {
          imageUrl = PDFSign;
          return imageUrl;
        }
        if (book.type === "url") {
          imageUrl = URLSign;
          return imageUrl;
        }
        if (book.type === "youtube") {
          imageUrl = YouTubeSign;
          return imageUrl;
        }
        return imageUrl;
      } else if (book.sources && book.sources.length > 1) {
        // eslint-disable-next-line
        book.sources.map((source: any) => {
          if (source.type === "aws") {
            isPdf = true;
          } else if (source.type === "url") {
            isUrl = true;
          } else if (source.type === "youtube") {
            isYT = true;
          }
          if (isPdf && isYT && isUrl) {
            imageUrl = PdfYoutubeUrl;
          }
          if (isPdf && isYT && !isUrl) {
            imageUrl = PdfYoutube;
          }
          if (isPdf && !isYT && isUrl) {
            imageUrl = PdfUrl;
          }
          if (!isPdf && isYT && isUrl) {
            imageUrl = YoutubeUrl;
          }
          if (!isPdf && isYT && !isUrl) {
            imageUrl = YouTubeSign;
          }
          if (isPdf && !isYT && !isUrl) {
            imageUrl = PDFSign;
          }
          if (!isPdf && !isYT && isUrl) {
            imageUrl = URLSign;
          }
        });
        return imageUrl;
      } else {
        book.sources &&
          book.sources.length === 1 &&
          book.sources.forEach((source: any) => {
            if (source.type === "aws") {
              imageUrl = PDFSign;
            } else if (source.type === "url") {
              imageUrl = URLSign;
            } else if (source.type === "youtube") {
              imageUrl = YouTubeSign;
            }
          });
        return imageUrl;
      }
    }

    return imageUrl;
  };
  useEffect(() => {
    if (bookId) {
      socket.emit("bookId", {
        bookId: bookId,
      });
      socket.off("bookId-event");
      socket.on("bookId-event", (data) => {
        if (data) {
          if (data.book) {
            setTimeout(() => {
              setIndexingProgress(false);
              socket.off("bookId-event");
              socket.disconnect();
              navigate(`/public/books/${data.book.id}`);
            }, 60000);
          }
        }
      });
    }
    // eslint-disable-next-line
  }, [bookId]);

  return (
    <MainSectionParent id="featured-chatbots" fluid>
      <Row>
        <Col md={5} xs={12} style={{ paddingLeft: "8px", display: "block" }}>
          <div style={{ marginTop: "30px" }}>
            {indexingProgress ? (
              <RightColumn>
                <LoaderForWaiting src={LoaderImage} width={150} />
                <WaitingTitle>Please Wait...</WaitingTitle>
                <WaitingDescription>
                  Processing the file will take a few minutes. You have two
                  options: you can either wait for automatic redirection to the
                  chat page, or we can send you an email with the link to the
                  chat page when it's ready. You can safely close this window or
                  navigate to another tab while you wait.
                </WaitingDescription>

                <TryAnotherChatBot
                  onClick={() => {
                    setIndexingProgress(false);
                  }}
                >
                  Try Another Chatbot
                </TryAnotherChatBot>
              </RightColumn>
            ) : (
              <>
                <CreateBook setBookId={setBookId} bookId={bookId} />
              </>
            )}
          </div>
        </Col>

        <Col
          md={7}
          style={{
            paddingRight: "0",
            padding: "0",
          }}
        >
          <ParentDefaultBook>
            {publicBooks.length > 0 && (
              <>
                <HeadingSection>{featureChatbotHeading}</HeadingSection>
                <DecsriptionSection>
                  {featureChatbotSubHeading}
                </DecsriptionSection>
              </>
            )}

            <MainRow>
              {publicBooks && publicBooks.length > 0 ? (
                publicBooks.map((book: Book, idx) => (
                  <>
                    <Item
                      md={2}
                      xs={6}
                      key={idx}
                      background={
                        idx === 0
                          ? colors.firstBox
                          : idx === 1
                          ? colors.secondBox
                          : idx === 2
                          ? colors.thirdBox
                          : idx === 3
                          ? colors.fourthBox
                          : colors.commonBox
                      }
                    >
                      <Link
                        to={"/guest/books/" + (book._id ? book._id : book.id)}
                        onClick={() => {
                          Mixpanel.track("Demo Book Click", {
                            bookId: book._id ? book._id : book.id,
                            bookTitle: book.title,
                            bookAuthors: book.authors,
                          });
                        }}
                      >
                        <FirstInnerParent>
                          <ImageDiv>
                            <ImageTag src={bookImageURL(book)} alt="chatbot" />
                          </ImageDiv>
                          <TitleSpan>{book.title}</TitleSpan>
                        </FirstInnerParent>
                      </Link>
                    </Item>
                  </>
                ))
              ) : (
                <PlateFormImageDiv>
                  <img
                    src={`https://drop-chat-files.s3.amazonaws.com/dropchat-plateform.svg`}
                    alt="plateform"
                    style={{ width: "49%" }}
                  />
                  <HeadingSection>Dropchat Platform</HeadingSection>
                  <DecsriptionSection
                    style={{
                      textAlign: "center",
                      fontSize: "14px",
                      marginTop: "10px",
                    }}
                  >
                    The Dropchat Platform leverages Retrieval Augmented
                    Generation (RAG), which connects LLMs to an external data
                    source. These data sources provide current and
                    context-specific information, enhancing the LLM's ability to
                    generate more accurate responses.
                  </DecsriptionSection>
                </PlateFormImageDiv>
              )}
            </MainRow>

            {/* <Row style={{ overflow: "scroll", width: "100%" }}>
              {indexingProgress ? (
                <RightColumn>
                  <LoaderForWaiting src={LoaderImage} width={150} />
                  <WaitingTitle>Please Wait...</WaitingTitle>
                  <WaitingDescription>
                    Processing the file will take a few minutes. You have two
                    options: you can either wait for automatic redirection to
                    the chat page, or we can send you an email with the link to
                    the chat page when it's ready. You can safely close this
                    window or navigate to another tab while you wait.
                  </WaitingDescription>
                </RightColumn>
              ) : (
                <PlateFormImageDiv>
                  <img
                    src={DropChatPlateFormSvg}
                    alt="plateform"
                    style={{ width: "49%" }}
                  />
                  <HeadingSection>Dropchat Platform</HeadingSection>
                  <DecsriptionSection
                    style={{
                      textAlign: "center",
                      fontSize: "14px",
                      marginTop: "10px",
                    }}
                  >
                    The Dropchat Platform leverages Retrieval Augmented
                    Generation (RAG), which connects LLMs to an external data
                    source. These data sources provide current and
                    context-specific information, enhancing the LLM's ability to
                    generate more accurate responses.
                  </DecsriptionSection>
                </PlateFormImageDiv>
              )}
            </Row> */}
          </ParentDefaultBook>
        </Col>
      </Row>
    </MainSectionParent>
  );
};

export default BookFunctionalityTrialPage;
