export const USER_ADD_A_BOOK = "user-add-a-book";
export const USER_ADDED_SOURCE_IN_A_CHATBOT = "user-added-source-in-a-chatbot";
export const USER_DELETE_A_BOOK = "user-delete-a-book";
export const USER_GOES_ABOVE_BOOK_COUNT =
  "user-goes-above-book-count-configured-in-admin-settings";
export const USER_GOES_ABOVE_TOKEN_COUNT =
  "user-goes-above-token-count-configured-in-admin-settings";
export const USER_ASK_BOOK_A_QUESTION = "user-asks-book-a-question";
export const GUEST_USER_CREATED_A_CHATBOT = "guest-user-created-a-chatbot";
export const TRIAL_USER_ASKED_A_QUESTION = "trial-user-asked-a-question";
export const GUEST_USER_ASK_A_QUESTION_ON_DEMO_CHATBOT =
  "guest-user-asks-a-question-on-demo-chatbot";
export const USER_CREATED_A_BUBBLE_CHATBOT = "user-created-a-bubble-chatbot";
export const USER_ASKED_A_QUESTION_FROM_BUBBLE_CHATBOT =
  "user-asked-a-question-from-bubble-chatbot";
export const USER_CREATED_A_IFRAME_CHATBOT = "user-created-a-iframe-chatbot";
export const USER_CREATED_A_SHARE_URL_CHATBOT =
  "user-created-a-share-url-chatbot";
export const USER_ASKED_A_QUESTION_FROM_SHARE_OR_IFRAME_URL_CHATBOT =
  "user-asked-a-question-from-share-or-iframe-url-chatbot";
export const USER_REQUESTED_TO_CANCEL_PLAN = "user-requested-to-cancel-plan";
export const USER_CANCELLED_DOWNGRADE_PLAN = "user-cancelled-downgrade-plan";
export const USER_TRIED_TO_PURCHASE_PLAN = "user-tried-to-purchase-plan";
