import { Field, Form, Formik } from "formik";
import { useContext, useEffect, useRef, useState } from "react";
import { Col, FormLabel } from "react-bootstrap";
import { NotificationManager } from "react-notifications";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { MEDIUM } from "../../../constants/desktop";
import { MOBILE } from "../../../constants/mobile";
import { TAB } from "../../../constants/tab";
import { BookContext } from "../../../context/BookContext";
import { UserContext } from "../../../context/UserContext";
import { LoaderContext } from "../../../context/loader-context";
import { PlanContext } from "../../../context/plan-context";
import {
  ChatBotEvents,
  EventCategory,
  SubCategory,
} from "../../../event-utils/dropchat-event-enums";
import { getUsersEmail } from "../../../event-utils/dropchat-event-track";
import { trackEvent } from "../../../event-utils/track-event";
import myAxios from "../../../myaxios";
import { ADD_TEXT_SOURCE_URL } from "../../../utils/class-names";
import { parseErrorResponse } from "../../../utils/utils";
import IndexCharacterLimitPopUp from "../../index-character-limit-popup";
import UploadComponent from "../../utility-component/text-drag-drop-component";

const ParentSection = styled.div`
  width: 100%;
`;

const InnerSection = styled.div`
  width: 100%;
  border-radius: 8px;
`;

const FieldSection = styled.div`
  margin-bottom: 10px;
  flex-direction: column;
`;

const FormError = styled.div`
  margin: 0rem 0rem 1rem;
  text-align: left;
  color: red;
`;

const MainDiv = styled.div`
  width: 100%;
`;

const DivForDltPopUp = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 78vh;
`;
const ParentDiv = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  z-index: 9999;
`;

const EmptyDiv = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  background: #252424cc;
  height: 100%;
  width: 100vw;
`;
const SubscriptionExpireOverlay = styled.div`
  position: absolute;
  height: 50vh;
  width: 50vw;
  z-index: 1;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 0;

  @media (max-width: ${MOBILE.maxWidth}px) {
    position: fixed;
    bottom: 25vh;
    width: 80vw;
    right: 10vw;
  }
`;

interface SaveButtonProps {
  displayProp?: boolean; // Declare the custom prop and its type
}

const SubmitButton = styled.button<SaveButtonProps>`
  background: #1585b5;
  padding: 0.4rem 3rem;
  color: #fff;
  border: 1px solid transparent;
  width: auto;
  display: flex;
  margin-top: auto;
  justify-content: center;
  display: ${(props) => (props.displayProp ? " flex" : " none")};
  &:disabled {
    display: ${(props) => (props.displayProp ? " flex" : " none")};
    opacity: ${(props) => (props.displayProp ? " 0.5" : " 0")};
    cursor: not-allowed;
  }
  @media (max-width: ${MOBILE.maxWidth}px) {
    padding: 0.5rem;
    font-size: 13px;
    width: 96vw;
    margin-top: 0.2rem;
  }
`;

const URLFetchForm = styled(Form)`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const UrlFormLabel = styled(FormLabel)`
  font-weight: 500;
`;

const FormInput = styled(Field)`
  width: 100%;
  padding: 10px;
`;
const FormInputTextArea = styled.textarea`
  width: 100%;
  padding: 10px;
  height: 17rem;
`;

const UrlSection = styled.div`
  margin: 1rem;
  width: 100%;
  flex-direction: column;
`;

const FooterSection = styled.div`
  background: #ffffff;
  box-shadow: 0px 0px 20px 0px #0000001a;
  padding: 0.5rem;
  margin-top: auto;
  justify-content: space-between;
  align-items: center;

  @media (max-width: ${MOBILE.maxWidth}px) {
    width: 100%;
    justify-content: space-between;
    flex-direction: column-reverse;
  }
`;
const FooterInnerDiv = styled.div`
  align-items: center;
  @media (max-width: ${MOBILE.maxWidth}px) {
    display: flex;
    flex-direction: column;
    // align-items: end;
  }
  @media (min-width: ${TAB.minWidth}px) {
    display: flex;
    flex-direction: column;
  }
  @media (min-width: ${MEDIUM.minWidth}px) {
    flex-direction: row;
  }
`;

const CancelButton = styled.button`
  background: none;
  padding: 0.4rem 3rem;
  color: #2f3349;
  border: 1px solid #2f3349;
  width: auto;
  display: flex;
  margin-top: auto;
  justify-content: center;
  @media (max-width: ${MOBILE.maxWidth}px) {
    padding: 0.5rem;
    font-size: 13px;
    width: 100%;
    margin-top: 0.5rem;
  }
`;

const UpgradeSpan = styled.span`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 0px;
  padding: 5px 25px;
  background: #f2ae01;
  border-radius: 4px;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  color: #ffffff;
  cursor: pointer;
`;

const PlanSpan = styled.span`
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 15px;
  color: #585858;
  margin-left: 5px;
  margin-top: 1px;
  display: flex;
  align-items: center;
`;

const SelectedCount = styled.span`
  color: #000;
  font-size: 12px;
  font-weight: 500;
  padding-left: 1rem;
  padding-right: 1rem;
  align-items: center;
  display: flex;
  @media (max-width: ${MOBILE.maxWidth}px) {
    justify-content: space-around !important;
    display: flex;
    font-size: 13px;
    padding-right: "0rem";
  }
  @media (min-width: ${TAB.minWidth}px) {
    justify-content: space-around !important;
    display: flex;
    font-size: 11px;
  }
  @media (min-width: ${MEDIUM.minWidth}px) {
    color: #000;
    font-size: 13px;
    font-weight: bold;
    padding-left: 1rem;
    align-items: center;
    display: flex;
  }
`;

const CustomButton = styled.button`
  background: #f2ae01;
  border-radius: 2px;
  color: white;
  border: none;
  font-size: 16px;
  cursor: pointer;
  padding: 5px 15px;
  margin-left: 1rem;
  &:disabled {
    display: none;
    opacity: 0.5;
    cursor: not-allowed;
  }
  @media (max-width: ${MOBILE.maxWidth}px) {
    margin-left: 0;
    padding: 0.5rem;
    font-size: 13px;
    width: 96vw;
    margin-top: 0.2rem;
  }
`;

const InputFieldRadio = styled(Field)`
  width: auto;
  margin-top: 0.2rem;
  margin-right: 0.4rem;
  cursor: pointer;
  -ms-transform: scale(1.5); /* IE 9 */
  -webkit-transform: scale(1.5); /* Chrome, Safari, Opera */
  transform: scale(1.5);
`;

const TextAreaLimitSpan = styled.span`
  max-width: max-content;
  padding: 2px 10px;
  background: #1585b5;
  color: #fff;
  font-size: 12px;
`;

const AddTextSource = ({ setShowAddNewSourcePage }: any) => {
  const navigate = useNavigate();
  const { setPlanHighliter } = useContext(PlanContext);
  const { user } = useContext(UserContext);
  const { setShowLoader } = useContext(LoaderContext);
  const { selectedBook, updateBookIndex } = useContext(BookContext);
  const [fileObject, setFileObject] = useState<any>();
  const [totalTextCharacterCount, setTotalTextCharacterCount] = useState(0);
  const [disableTextIndexLimit, setDisableTextIndexLimitState] =
    useState<boolean>(false);
  const [showLimitPopUp, setShowLimitPopUp] = useState<boolean>(false);

  const formRef: any = useRef();
  const validateForm = (formData: any) => {
    const errors: any = {};
    if (!formData.title || formData.title.lenght === 0) {
      errors.title = "Please enter title!";
    }

    if (!formData.text || formData.text.lenght === 0) {
      errors.text = "Please enter text!";
    }

    return errors;
  };

  useEffect(() => {
    const indexingCharacterLimitCount = Math.max(
      (user?.currentSubscription?.meta?.indexCharacterSupport
        ? user?.currentSubscription?.meta?.indexCharacterSupport
        : 0) -
        (user?.currentSubscription?.indexCharacterUsedTotal
          ? user?.currentSubscription?.indexCharacterUsedTotal
          : 0),
      0
    );
    if (totalTextCharacterCount > indexingCharacterLimitCount) {
      setDisableTextIndexLimitState(true);
    } else {
      setDisableTextIndexLimitState(false);
    }
  }, [totalTextCharacterCount, setTotalTextCharacterCount]);

  return (
    <ParentSection>
      <Col sm={12} xs={12}>
        <InnerSection>
          <>
            <MainDiv>
              <DivForDltPopUp>
                <div style={{ width: "100%" }}>
                  <UrlSection>
                    <Formik
                      innerRef={formRef}
                      initialValues={{
                        title: "",
                        text: "",
                        type: "text",
                        textType: "textWrite",
                      }}
                      validate={validateForm}
                      onSubmit={(values, { resetForm }) => {
                        setShowAddNewSourcePage(false);
                        setShowLoader(true);
                        myAxios
                          .post(
                            process.env.REACT_APP_SERVER_URL +
                              `/books/add-source/${selectedBook?._id}`,
                            [values]
                          )
                          .then((response) => {
                            // console.log("response is , ", response.data);
                            if (response.data && response.data.success) {
                              trackEvent({
                                email: getUsersEmail(user),
                                eventCategory: EventCategory.CHATBOT,
                                event:
                                  ChatBotEvents.USER_ADDED_NEW_DATASOURCE_IN_EXISTING_CHATBOT,
                                subCategory: SubCategory.EVENT,
                                chatbotType: response.data.data.fileType,
                                bookId: response.data.data._id,
                                rawData: {
                                  addedSourceType: "TEXT",
                                },
                              });
                              setShowLoader(false);
                              updateBookIndex(response.data.data);
                              setShowAddNewSourcePage(false);
                              NotificationManager.success(
                                response.data.message
                                  ? response.data.message
                                  : "Source saved successfully."
                              );
                            } else {
                              setShowLoader(false);
                              NotificationManager.error(
                                response.data.message
                                  ? response.data.message
                                  : "Unable to process your request, please try later"
                              );
                            }
                          })
                          .catch((error) => {
                            // handle error
                            setShowLoader(false);
                            NotificationManager.error(
                              parseErrorResponse(error)
                            );
                          });
                      }}
                    >
                      {({ values, errors, touched, setFieldValue }: any) => (
                        <URLFetchForm className={ADD_TEXT_SOURCE_URL}>
                          <UrlFormLabel>Title</UrlFormLabel>
                          <FieldSection>
                            <FormInput name="title" placeholder="title" />
                          </FieldSection>
                          {errors.title && touched.title && (
                            <FormError className="formError">
                              {errors.title}
                            </FormError>
                          )}
                          <div style={{ margin: "0.5rem 0" }}>
                            <div style={{ marginRight: "1rem" }}>
                              <InputFieldRadio
                                type="radio"
                                name="textType"
                                value="textWrite"
                                onClick={() => setTotalTextCharacterCount(0)}
                              />{" "}
                              Write Text
                            </div>
                            <div>
                              <InputFieldRadio
                                type="radio"
                                name="textType"
                                value="textFile"
                                onClick={() => setTotalTextCharacterCount(0)}
                              />{" "}
                              Upload Text File
                            </div>
                          </div>
                          {values.textType === "textWrite" ? (
                            <>
                              <UrlFormLabel>Text</UrlFormLabel>
                              <FieldSection>
                                <FormInputTextArea
                                  name="text"
                                  placeholder="Type text here"
                                  maxLength={100000}
                                  onChange={(e) => {
                                    setTotalTextCharacterCount(
                                      e.target.value.length
                                    );
                                    setFieldValue("text", e.target.value);
                                  }}
                                />
                                <TextAreaLimitSpan
                                  style={{
                                    background:
                                      totalTextCharacterCount === 100000
                                        ? "red"
                                        : "#1585b5",
                                  }}
                                >
                                  {totalTextCharacterCount === 0
                                    ? 0
                                    : totalTextCharacterCount}
                                  /100000
                                </TextAreaLimitSpan>
                              </FieldSection>
                              {errors.text && touched.text && (
                                <FormError className="formError">
                                  {errors.text}
                                </FormError>
                              )}
                            </>
                          ) : (
                            <FieldSection>
                              <UploadComponent
                                setFieldValue={setFieldValue}
                                setTotalTextCharacterCount={
                                  setTotalTextCharacterCount
                                }
                                fileObject={fileObject}
                                setFileObject={setFileObject}
                              />
                            </FieldSection>
                          )}
                        </URLFetchForm>
                      )}
                    </Formik>
                  </UrlSection>
                </div>

                <FooterSection>
                  <CancelButton
                    type="button"
                    onClick={() => setShowAddNewSourcePage(false)}
                  >
                    Cancel
                  </CancelButton>
                  {user?.currentSubscription ? (
                    user?.currentSubscription.meta?.allowWebsiteIndex ? (
                      <FooterInnerDiv>
                        <SelectedCount
                          style={{
                            color: disableTextIndexLimit ? "red" : "grey",
                          }}
                        >
                          {totalTextCharacterCount} {" / "}
                          {Math.max(
                            (user?.currentSubscription?.meta
                              ?.indexCharacterSupport
                              ? user?.currentSubscription?.meta
                                  ?.indexCharacterSupport
                              : 0) -
                              (user?.currentSubscription
                                ?.indexCharacterUsedTotal
                                ? user?.currentSubscription
                                    ?.indexCharacterUsedTotal
                                : 0),
                            0
                          )}{" "}
                          {disableTextIndexLimit
                            ? "Characters limit exceeds"
                            : "Characters"}
                        </SelectedCount>
                        <SubmitButton
                          type="button"
                          disabled={totalTextCharacterCount === 0}
                          displayProp={disableTextIndexLimit ? false : true}
                          onClick={() => {
                            if (formRef.current) {
                              formRef.current.handleSubmit();
                            }
                          }}
                        >
                          Add Data Source
                        </SubmitButton>
                        <CustomButton
                          type="button"
                          disabled={!disableTextIndexLimit}
                          onClick={() => {
                            setShowLimitPopUp(true);
                            setPlanHighliter({
                              isBookHighliter: false,
                              bookLimit: 0,
                              isDeleteBookHighliter: false,
                              isFlashcardHighliter: false,
                              isCharacterLimit: false,
                              isIndexCharacterLimit: true,
                              pdfLimit: 0,
                              isDeleteAccount: false,
                              isLeadLoginSettings: false,
                              isAllowYTIndex: false,
                            });
                          }}
                        >
                          Upgrade Plan
                        </CustomButton>
                      </FooterInnerDiv>
                    ) : (
                      <div>
                        <SubmitButton
                          type="button"
                          style={{ opacity: 0.5, cursor: "not-allowed" }}
                        >
                          Add Data Source
                        </SubmitButton>
                        <div style={{ paddingLeft: "2rem" }}>
                          <UpgradeSpan
                            onClick={(e) => {
                              setPlanHighliter({
                                isBookHighliter: false,
                                bookLimit: 0,
                                isDeleteBookHighliter: false,
                                isFlashcardHighliter: false,
                                isCharacterLimit: false,
                                pdfLimit: 0,
                                isDeleteAccount: false,
                                isLeadLoginSettings: false,
                                isAllowYTIndex: false,
                                isAllowPDFIndex: false,
                                isAllowWebsiteIndex: false,
                              });
                              navigate("/subscription");
                            }}
                          >
                            Upgrade Plan
                          </UpgradeSpan>
                          {/* <PlanSpan>Your plan</PlanSpan> */}
                        </div>
                      </div>
                    )
                  ) : (
                    <Col>
                      <SubmitButton
                        type="button"
                        style={{ opacity: 0.5, cursor: "not-allowed" }}
                      >
                        Add Data Source
                      </SubmitButton>
                      <div style={{ paddingLeft: "2rem" }}>
                        <UpgradeSpan
                          onClick={(e) => {
                            setPlanHighliter({
                              isBookHighliter: false,
                              bookLimit: 0,
                              isDeleteBookHighliter: false,
                              isFlashcardHighliter: false,
                              isCharacterLimit: false,
                              pdfLimit: 0,
                              isDeleteAccount: false,
                              isLeadLoginSettings: false,
                              isAllowYTIndex: false,
                              isAllowPDFIndex: false,
                              isAllowWebsiteIndex: false,
                            });
                            navigate("/subscription");
                          }}
                        >
                          Purchase Plan
                        </UpgradeSpan>
                      </div>
                    </Col>
                  )}
                </FooterSection>
              </DivForDltPopUp>
            </MainDiv>
          </>
        </InnerSection>
      </Col>
      {showLimitPopUp ? (
        <ParentDiv>
          <EmptyDiv
            onClick={() => {
              setShowLimitPopUp(false);
            }}
          ></EmptyDiv>
          <SubscriptionExpireOverlay>
            <IndexCharacterLimitPopUp
              titleContent={"Text character limit exceeded"}
              subHeadingContent={"Text character limit exceeded"}
              mainContent={`Please note that you have exceeded the Text character limit of
                 your current subscription plan. To resolve this, you can either remove
                 some of the existing links or consider upgrading your plan for a
                 higher character limit.`}
              setShowLimitPopUp={setShowLimitPopUp}
            ></IndexCharacterLimitPopUp>
          </SubscriptionExpireOverlay>
        </ParentDiv>
      ) : null}
    </ParentSection>
  );
};

export default AddTextSource;
