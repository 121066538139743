import { Formik } from "formik";
import { useContext, useState } from "react";
import { Container, Row } from "react-bootstrap";
import { NotificationManager } from "react-notifications";
import styled from "styled-components";
import { MOBILE } from "../../constants/mobile";
import { PublicContext } from "../../context/PublicContext";
import OrLInes from "../../images/oricon.svg";
import SuccessIcon from "../../images/successicon.svg";
import myAxios from "../../myaxios";
import { parseErrorResponse } from "../../utils/utils";

const DivForLoginWithCredintial = styled.div`
  display: flex;
  justify-content: center;
  cursor: pointer;
  margin-top: 3%;
`;
const ButtonForCred = styled.button`
  width: 100%;
  height: 50px;
  background: #ffffff;
  border: 1px solid #1585b5;
  border-radius: 3px;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  justify-content: center;
  text-align: center;
  display: flex;
  align-items: center;
  color: #1585b5;
  cursor: pointer;
  z-index: 11111;
`;
const BottomDiv = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 10px;
`;
const SpanAccount = styled.span`
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #1585b5;
`;
const SpanSignup = styled.span`
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: #1585b5;
  margin-left: 7px;
  cursor: pointer;
`;

const DIV = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const DivForPara = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
const LoginPara = styled.span`
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  color: #1585b5;
`;

const SuccessMessage = styled.span`
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  color: #1585b5;
  text-align: center;
  margin: 1rem;
`;

const LoginParaVeri = styled.span`
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 48px;
  color: #fc6601;
  padding: 24px 0;
`;

const LoginSignupFieldsDiv = styled.div`
  padding-top: 10px;
  display: flex;
  justify-content: center;
  flex-direction: column;
`;
const Input = styled.input`
  width: 100%;
  background: #ffffff;
  border: 1px solid rgba(51, 48, 60, 0.6);
  border-radius: 4px;
  padding: 0.7rem;
  margin-bottom: 1rem;
  @media (max-width: ${MOBILE.maxWidth}px) {
    border-radius: 4px;
    margin-bottom: 0.5rem;
  }
`;
const DivForLoginButton = styled.div`
  padding: 10px 0;
  width: 100%;
  text-align: center;
  margin-bottom: 10px;
`;

const LoginButton = styled.button`
  width: 100%;
  height: 50px;
  color: white;
  background: #1585b5;
  border-radius: 3px;
  border: none;
  cursor: pointer;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #ffffff;
  &:hover {
    background: #094159bd;
    border: none;
  }
`;
const SpanErrorLoginSignup = styled.span`
  font-style: normal;
  font-weight: 500;
  font-size: 11px;
  line-height: 12px;
  color: #df5c04;
  width: 100%;
  display: flex;
`;

const SuceessImgTag = styled.img`
  width: 50px;
  margin: 50px;
`;
const TextSpanSuccess = styled.span`
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  display: flex;
  align-items: center;
  text-align: center;

  color: #797979;
`;

const DivForOrLine = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;
const OrlinesImgTag = styled.img`
  width: 64%;
`;
const Label = styled.label`
  font-weight: 300;
  font-size: 14px;
  color: #33303c;
  margin-bottom: 2px;
`;

const ForgetPasswordRenewed = () => {
  const { ShowConditionalLoginPage, setShowConditionalLoginPage } =
    useContext(PublicContext);
  // eslint-disable-next-line
  const [toShowEmailVerificationSuccess, setToShowEmailVerificationSuccess] =
    useState<boolean>(false);
  // eslint-disable-next-line
  const [saveSuccessMsg, setSaveSuccessMsg] = useState<string | undefined>("");
  // eslint-disable-next-line
  const [forgotPassLoader, setForgotPassLoader] = useState(false);
  const [showSuccessMessage, setShowSuccessMessage] = useState<boolean>(false);

  const forgotPassword = (values: any, resetForm: any) => {
    setForgotPassLoader(true);
    myAxios
      .post(`auth/forgot-password`, {
        email: values.email,
      })
      .then((response: any) => {
        if (response.data && response.data.success) {
          setForgotPassLoader(true);
          setShowSuccessMessage(true);
          NotificationManager.success(response.data.message);
        } else {
          setForgotPassLoader(true);
          NotificationManager.error(
            response.data.message
              ? response.data.message
              : "Unable to process your request, please try later"
          );
        }
      })
      .catch((error: any) => {
        setForgotPassLoader(true);
        // console.error("error", error);
        NotificationManager.error(parseErrorResponse(error));
      });
  };

  return (
    <>
      <Container>
        {!showSuccessMessage ? (
          <Row>
            <DIV>
              <DivForPara>
                {!toShowEmailVerificationSuccess ? (
                  <LoginPara>Forgot Password</LoginPara>
                ) : (
                  <LoginParaVeri>Email Verification</LoginParaVeri>
                )}
              </DivForPara>
            </DIV>

            {!toShowEmailVerificationSuccess ? (
              <Formik
                initialValues={{
                  email: "",
                }}
                validate={(values: any) => {
                  const errors: {
                    email?: string;
                  } = {};

                  if (!values.email) {
                    errors.email = " email required";
                  } else if (
                    !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(
                      values.email
                    )
                  ) {
                    errors.email = "Invalid email address";
                  }

                  return errors;
                }}
                onSubmit={(values, { setSubmitting, resetForm }) => {
                  forgotPassword(values, resetForm);
                }}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  isSubmitting,
                }) => (
                  <form onSubmit={handleSubmit}>
                    <LoginSignupFieldsDiv>
                      <Label>Enter Email</Label>
                      <Input
                        type="email"
                        // placeholder="Enter your email"
                        name="email"
                        onChange={handleChange}
                      />

                      {errors.email && (
                        <SpanErrorLoginSignup>
                          {errors.email && touched.email && errors.email}
                        </SpanErrorLoginSignup>
                      )}

                      <DivForLoginButton>
                        <LoginButton type="submit">CONTINUE</LoginButton>
                      </DivForLoginButton>
                    </LoginSignupFieldsDiv>
                  </form>
                )}
              </Formik>
            ) : (
              <div
                style={{
                  height: "auto",
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <SuceessImgTag src={SuccessIcon} />
                <TextSpanSuccess>{saveSuccessMsg}</TextSpanSuccess>
              </div>
            )}

            <DivForOrLine>
              <OrlinesImgTag src={OrLInes} alt="or" />
            </DivForOrLine>

            <DivForLoginWithCredintial>
              <ButtonForCred
                onClick={() => {
                  setShowConditionalLoginPage({
                    ...ShowConditionalLoginPage,
                    toShowMagicLinkloginPage: true,
                  });
                }}
              >
                Login with Magic Link
              </ButtonForCred>
            </DivForLoginWithCredintial>

            <BottomDiv>
              <SpanAccount>Don't have an account |</SpanAccount>
              <SpanSignup
                onClick={() => {
                  setShowConditionalLoginPage({
                    ...ShowConditionalLoginPage,
                    toShowSignupPage: true,
                  });
                }}
              >
                SIGNUP
              </SpanSignup>
            </BottomDiv>
          </Row>
        ) : (
          <DivForPara>
            <SuccessMessage>
              An email has been sent to registered email with further
              instructions
            </SuccessMessage>
          </DivForPara>
        )}
      </Container>
    </>
  );
};

export default ForgetPasswordRenewed;
