import { useContext, useEffect, useRef, useState } from "react";
import { Col, FormLabel, Row } from "react-bootstrap";
import styled from "styled-components";
import { MOBILE } from "../../../constants/mobile";
import { BookContext } from "../../../context/BookContext";
import { Formik } from "formik";
import { LoaderContext } from "../../../context/loader-context";
import myAxios from "../../../myaxios";
import { NotificationManager } from "react-notifications";
import UploadComponent from "../../utility-component/text-drag-drop-component";
import { UserContext } from "../../../context/UserContext";
import { TAB } from "../../../constants/tab";
import { MEDIUM } from "../../../constants/desktop";
import { parseErrorResponse } from "../../../utils/utils";

interface SaveButtonProps {
  displayProp?: boolean; // Declare the custom prop and its type
}

const ParentDiv = styled.div`
  overflow: hidden;
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  width: 100vw;
  background: rgba(0, 0, 0, 0.5);
  z-index: 9999;
  align-items: center;
  justify-content: center;
`;
const EmptyDiv = styled.div`
  position: absolute;
  display: flex;
  top: 0;
  align-items: center;
  justify-content: center;
  left: 0;
  background: #252424cc;
  height: 100%;
  width: 100vw;
`;

const FieldSection = styled.div`
  margin-bottom: 10px;
  flex-direction: column;
`;
const FormLabelNew = styled(FormLabel)`
  font-weight: 600;
  font-size: 14px;
  @media (max-width: ${MOBILE.maxWidth}px) {
    font-size: 13px;
  }
`;
const TextAREANew = styled.textarea`
  border-radius: 4px;
  border: 1px solid #eaeaea;
  background: #fff;
  padding: 10px;
  font-size: 14px;
  min-height: 50vh;
  @media (max-width: ${MOBILE.maxWidth}px) {
    font-size: 12px;
  }
`;
const InputField = styled.input`
  width: 100%;
  padding: 10px;
  background: #fff;
  border: 1px solid #eaeaea !important;
  display: inline;
  border-radius: 4px;
  @media (max-width: ${MOBILE.maxWidth}px) {
    font-size: 13px;
    border-radius: 4px;
  }
`;
const Container = styled(Col)`
  position: fixed;
  display: block;
  width: 40vw;
  margin: auto;
  overflow: auto;
  max-height: 85vh;
  inset: 0px;
  background-color: rgb(255, 255, 255);
  z-index: 999999;
  border-radius: 10px 10px 0px 0px;
`;
const DltHeader = styled(Row)`
  height: 45px;
  background: rgba(47, 51, 73, 1);
  border-radius: 10px 10px 0px 0px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const SpanDltPopup = styled.span`
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 12px;
  color: #2f4c95;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 0;
  color: white;
  @media (max-width: ${MOBILE.maxWidth}px) {
    color: white;
  }
`;
const MainFormRow = styled(Row)`
  padding: 0.5rem 2rem;
`;

const ButtonRow = styled(Row)`
  padding: 0rem 3rem;
  display: flex;
  justify-content: space-between;
  //   align-items: center;
`;

const UpdateButton = styled.button<SaveButtonProps>`
  background: #1585b5;
  border-radius: 2px;
  color: white;
  border: none;
  font-size: 16px;
  cursor: pointer;
  width: auto !important;
  padding: 0.4rem 3rem;
  display: ${(props) => (props.displayProp ? " flex" : " none")};
  &:disabled {
    display: ${(props) => (props.displayProp ? " flex" : " none")};
    opacity: ${(props) => (props.displayProp ? " 0.5" : " 0")};
    cursor: not-allowed;
    opacity: 0.5;
  }
`;

const CancelButton = styled.button`
  background: none;
  padding: 0.4rem 3rem;
  color: #2f3349;
  border: 1px solid #2f3349;
  width: auto !important;
  display: flex;
  margin-top: auto;
  justify-content: center;
  @media (max-width: ${MOBILE.maxWidth}px) {
    padding: 0.5rem;
    font-size: 13px;
    width: 100%;
    margin-top: 0.5rem;
  }
`;

const TextAreaLimitSpan = styled.span`
  max-width: max-content;
  padding: 2px 10px;
  background: #1585b5;
  color: #fff;
  font-size: 12px;
`;

const SelectedCount = styled.span`
  color: #000;
  font-size: 12px;
  font-weight: 500;
  padding-left: 1rem;
  padding-right: 1rem;
  align-items: left;
  display: flex;
  @media (max-width: ${MOBILE.maxWidth}px) {
    display: flex;
    font-size: 13px;
    padding-right: "0rem";
  }
  @media (min-width: ${TAB.minWidth}px) {
    display: flex;
    font-size: 11px;
  }
  @media (min-width: ${MEDIUM.minWidth}px) {
    color: #000;
    font-size: 13px;
    font-weight: bold;
    padding-left: 1rem;
    align-items: left;
    display: flex;
  }
`;

const EditTextChatbot = ({ editPopUp, dataSource, toBeEdit }: any) => {
  const { selectedBook, setSelectedBook, updateBookTitle } =
    useContext(BookContext);
  const { user } = useContext(UserContext);
  const { setShowLoader } = useContext(LoaderContext);
  const [textContent, setTextContent] = useState<string>("");
  const [fileObject, setFileObject] = useState<any>();
  const [totalTextCharacterCount, setTotalTextCharacterCount] = useState(0);
  const [disableTextIndexLimit, setDisableTextIndexLimitState] =
    useState<boolean>(false);

  const textAreaRef1 = useRef<HTMLTextAreaElement>(null);

  const hidePopup = () => {
    editPopUp(false);
  };

  useEffect(() => {
    if (dataSource.textType === "textWrite") {
      fetch(dataSource.awsUrl)
        .then((response) => response.text())
        .then((data) => {
          // console.log(data);
          setTextContent(data);
        });
    }
  }, [dataSource]);

  useEffect(() => {
    const indexingCharacterLimitCount = Math.max(
      (user?.currentSubscription?.meta?.indexCharacterSupport
        ? user?.currentSubscription?.meta?.indexCharacterSupport
        : 0) -
        (user?.currentSubscription?.indexCharacterUsedTotal
          ? user?.currentSubscription?.indexCharacterUsedTotal
          : 0),
      0
    );
    if (totalTextCharacterCount > indexingCharacterLimitCount) {
      setDisableTextIndexLimitState(true);
    } else {
      setDisableTextIndexLimitState(false);
    }
  }, [totalTextCharacterCount, setTotalTextCharacterCount]);

  return (
    <ParentDiv>
      <EmptyDiv onClick={hidePopup}></EmptyDiv>
      <Container>
        <Formik
          initialValues={{
            bookId: selectedBook?._id,
            chatbotId: dataSource._id,
            text: textContent || "",
            textType: dataSource.textType,
          }}
          // validate={}
          enableReinitialize
          onSubmit={(values: any) => {
            const postData = {
              ...values,
            };
            setShowLoader(true);

            // NotificationManager.info("Under development!");

            myAxios
              .post(
                process.env.REACT_APP_SERVER_URL + "/books/update-text-chatbot",
                postData
              )
              .then((response) => {
                setShowLoader(false);
                NotificationManager.success(
                  response.data.message
                    ? response.data.message
                    : "Source updated successfully."
                );
              })
              .catch((error) => {
                // handle error
                setShowLoader(false);
                NotificationManager.error(parseErrorResponse(error));
              });
            hidePopup();
            toBeEdit = null;
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
            setFieldValue,
          }) => (
            <>
              <form onSubmit={handleSubmit}>
                <DltHeader>
                  <SpanDltPopup>Edit Text Chatbot</SpanDltPopup>
                </DltHeader>
                <MainFormRow>
                  {dataSource.textType === "textFile" && (
                    <FormLabelNew
                      style={{ textAlign: "center", fontSize: "16px" }}
                    >
                      The file was uploaded to create the text chatbot. Please
                      upload an updated version if necessary.
                    </FormLabelNew>
                  )}
                  {/* <FieldSection>
                    <FormLabelNew>Name</FormLabelNew>
                    <InputField
                      style={{ marginBottom: "0.5rem" }}
                      type="text"
                      name="title"
                      defaultValue={values.title}
                      placeholder="Chatbot Title"
                      onChange={handleChange}
                    />
                  </FieldSection> */}
                  {dataSource &&
                  dataSource.textType &&
                  dataSource.textType === "textWrite" ? (
                    <FieldSection>
                      <FormLabelNew>Text</FormLabelNew>
                      <TextAREANew
                        ref={textAreaRef1}
                        name="text"
                        defaultValue={values.text}
                        value={values.text}
                        onChange={(e) => {
                          setTotalTextCharacterCount(e.target.value.length);
                          setFieldValue("text", e.target.value);
                        }}
                      ></TextAREANew>
                      <TextAreaLimitSpan
                        style={{
                          background:
                            totalTextCharacterCount === 100000
                              ? "red"
                              : "#1585b5",
                        }}
                      >
                        {totalTextCharacterCount === 0
                          ? 0
                          : totalTextCharacterCount}
                        /100000
                      </TextAreaLimitSpan>
                    </FieldSection>
                  ) : (
                    <FieldSection>
                      <UploadComponent
                        setFieldValue={setFieldValue}
                        setTotalTextCharacterCount={setTotalTextCharacterCount}
                        fileObject={fileObject}
                        setFileObject={setFileObject}
                      />
                    </FieldSection>
                  )}
                  <SelectedCount
                    style={{
                      color: disableTextIndexLimit ? "red" : "grey",
                    }}
                  >
                    {totalTextCharacterCount} {" / "}
                    {Math.max(
                      (user?.currentSubscription?.meta?.indexCharacterSupport
                        ? user?.currentSubscription?.meta?.indexCharacterSupport
                        : 0) -
                        (user?.currentSubscription?.indexCharacterUsedTotal
                          ? user?.currentSubscription?.indexCharacterUsedTotal
                          : 0),
                      0
                    )}{" "}
                    {disableTextIndexLimit
                      ? "Characters limit exceeds"
                      : "Characters"}
                  </SelectedCount>
                </MainFormRow>
                <ButtonRow>
                  <CancelButton onClick={hidePopup}>Cancel</CancelButton>
                  <UpdateButton
                    type="submit"
                    disabled={totalTextCharacterCount === 0}
                    displayProp={disableTextIndexLimit ? false : true}
                  >
                    Update
                  </UpdateButton>
                </ButtonRow>
              </form>
            </>
          )}
        </Formik>
      </Container>
    </ParentDiv>
  );
};

export default EditTextChatbot;
