import axios from "axios";
import { Formik } from "formik";
import { useContext, useEffect, useState } from "react";
import { Col, Modal } from "react-bootstrap";
import { NotificationManager } from "react-notifications";
import { useNavigate, useParams } from "react-router-dom";
import { ChatSource } from "../../enums";
import SearchArrow from "../../images/chat-arrow.svg";
import BackIcon from "../../images/chat-back.svg";
import ChatBotIcon from "../../images/chat-bot-icon.svg";
import UserImage from "../../images/chat-user.svg";
import EPUBSign from "../../images/epubSign.svg";
import FLACSign from "../../images/flacSign.svg";
import HTMLSign from "../../images/htmlSign.svg";
import LoaderGif from "../../images/loader.gif";
import MDSign from "../../images/mdSign.svg";
import MDXSign from "../../images/mdxSign.svg";
import MP3Sign from "../../images/mp3Sign.svg";
import MP4Sign from "../../images/mp4Sign.svg";
import URLSign from "../../images/ogg1.svg";
import OGGSign from "../../images/oggSign.svg";
import PDFSign from "../../images/pdf1.svg";
import YouTubeSign from "../../images/ps_youtube.svg";
import TXTSign from "../../images/txtSign.svg";
import TypingLoading from "../../images/typingLoading.gif";
import WAVSign from "../../images/wavIcon.svg";
import TextSign from "../../images/txtSign.svg";
import myAxios from "../../myaxios";
import { Book, User } from "../../types";
import { isTestUser, parseErrorResponse } from "../../utils/utils";
// import QuestionsSideBar from "./old-questions-sidebar";
import { DEVICE_MEDIUM, DEVICE_MOBILE } from "../../constants/breakpoints";
import { PublicContext } from "../../context/PublicContext";
import {
  ChatBotEvents,
  EventCategory,
  Source,
  SubCategory,
  // UserEvents,
} from "../../event-utils/dropchat-event-enums";
import { getUsersEmail } from "../../event-utils/dropchat-event-track";
import { trackEvent } from "../../event-utils/track-event";
import { Mixpanel } from "../../mixpanel";
import { PUBLIC_USER_CHATBOT } from "../../utils/class-names";
import CustomMarkdown from "../../utils/custom-markdown";
import { Chat } from "../book-chat/chat";
import MagicLinkLoginPopup from "../magic-link-login-popup";
import PublicQuestionsSideBar from "./public-questions-sidebar";
import * as BookChatStyles from "./styled-public-trial";
// eslint-disable-next-line

const TeamBookChat = () => {
  const { deviceWidth } = useContext(PublicContext);
  const [currentSessionHistory, setCurrentSessionHistory] = useState<any[]>([]);
  // eslint-disable-next-line
  const [isTrialQuestionLoaded, setTrialQuestionLoaded] =
    useState<boolean>(false);

  const [allSessionHistory, setAllSessionHistory] = useState([]);

  const [loading, setLoading] = useState<boolean>(true);

  // eslint-disable-next-line
  const [showLoginPopup, setShowLoginPopup] = useState(false);

  const [formRef, setFormRef] = useState<any>();
  const [questionValue, setQuestionValue] = useState("");

  const { bookId } = useParams();

  const navigate = useNavigate();

  // const validatePropmt = (prompt: string) => {
  //   let error;
  //   if (!prompt) {
  //     error = "This is Required!";
  //   }
  //   return error;
  // };
  const [user, setUser] = useState<User>();
  const [localBook, setLocalBook] = useState<Book>({});
  const [showOverlay, setShowOverlay] = useState(false);
  // console.log("localBook------------", localBook);
  const [fileProcessingMessage, setFileProcessingMessage] =
    useState<string>("");

  useEffect(() => {
    if (bookId) {
      axios
        .get(
          process.env.REACT_APP_SERVER_URL + "/public/getFileProcessingMessage"
        )
        .then((response) => {
          if (response && response.data) {
            setFileProcessingMessage(response.data.data);
          }
        });
      axios
        .get(
          process.env.REACT_APP_SERVER_URL + "/public/get-trial-question-limit"
        )
        .then((response) => {
          if (response && response.data && response.data.data > 0) {
            setTrialQuestionLoaded(true);
          }
        });

      axios
        .get(process.env.REACT_APP_SERVER_URL + "/books/" + bookId)
        .then((response) => {
          // console.log("response-------", response);

          if (response.data) {
            setLocalBook(response.data);
            setUser(response.data.user);
          }
          setLoading(false);
        })
        .catch((error) => {
          setLoading(false);
          // console.error("Gettings Error at the time of load book ", error);
        });
    }
  }, [setLocalBook, setUser, bookId]);

  useEffect(() => {
    if (user) {
      myAxios
        .get(
          process.env.REACT_APP_SERVER_URL +
            "/get-history-of-chats/" +
            user?.id +
            "/" +
            bookId
        )
        .then((response) => {
          if (response.data) {
            setAllSessionHistory(response.data.data.allSessionHistory);
            setCurrentSessionHistory(response.data.data.currentSessionHistory);
          }
        })
        .catch((error) => {
          // console.error(
          //   "Getting error at the time of get chat history ",
          //   error
          // );
        });
    }
  }, [setAllSessionHistory, bookId, user]);

  const getFullAnswer = async (id: any, userQuery: any) => {
    axios
      .get(`${process.env.REACT_APP_SERVER_URL}/questions-and-answers/${id}`)
      .then((res: any) => {
        if (res.data) {
          const data = res.data.data;

          setCurrentSessionHistory((oldQuestions: any[]) => {
            if (oldQuestions.length > 0) {
              oldQuestions[oldQuestions.length - 1]._id = data._id;
              oldQuestions[oldQuestions.length - 1].id = data.id;
              oldQuestions[oldQuestions.length - 1].answer = data.answer;

              return [...oldQuestions];
            } else return [];
          });
          // checkAndShowLoginForm();
          if (!isTestUser(user?.email))
            Mixpanel.track("Public(Trial User) User Chat Response", {
              bookId: localBook._id ? localBook._id : localBook.id,
              bookTitle: localBook.title,
              bookAuthors: localBook.authors,
              question: userQuery,
              answer: data.answer,
              email: user?.email,
            });
          axios
            .put(
              process.env.REACT_APP_SERVER_URL +
                "/public/update-question-ask-count/" +
                user?._id
            )
            .then((response) => {
              if (response && response.data) {
                setUser(response.data.data);
              }
            })
            .catch((error) => {
              // console.error(
              //   "Gettings Error at the time of update question ask count ",
              //   error
              // );
            });
          setShowOverlay(false);
        } else {
          setShowOverlay(false);
          NotificationManager.error(
            res.data && res.data.message
              ? res.data.message
              : "Unable to process your request, please try later!"
          );
          if (!isTestUser(user?.email))
            Mixpanel.track("Public(Trial User) User Chat Response", {
              bookId: localBook._id ? localBook._id : localBook.id,
              bookTitle: localBook.title,
              bookAuthors: localBook.authors,
              question: userQuery,
              email: user?.email,
              answer: "Getting Error",
              errror:
                res.data && res.data.message
                  ? res.data.message
                  : "Unable to process your request, please try later!",
            });
        }
      })
      .catch((error) => {
        // handle error
        // console.error(
        //   "Getting Error at the time of ASK answer from chat gpt ",
        //   error
        // );
        setShowOverlay(false);
        NotificationManager.error(parseErrorResponse(error));
        if (!isTestUser(user?.email))
          Mixpanel.track("Public(Trial User) User Chat Response", {
            bookId: localBook._id ? localBook._id : localBook.id,
            bookTitle: localBook.title,
            bookAuthors: localBook.authors,
            question: userQuery,
            email: user?.email,
            answer: "Getting Error",
            errror: parseErrorResponse(error),
          });
      });
  };

  const chatWithDropchat = async (userQuery: string) => {
    setShowOverlay(true);
    setCurrentSessionHistory(
      currentSessionHistory
        ? [...currentSessionHistory, { question: userQuery, answer: "" }]
        : [{ question: userQuery, answer: "" }]
    );
    if (!isTestUser(user?.email)) Mixpanel.identify(user?.email);
    if (!isTestUser(user?.email))
      Mixpanel.track("Public(Trial User) User Try to Chat", {
        bookId: localBook._id ? localBook._id : localBook.id,
        bookTitle: localBook.title,
        bookAuthors: localBook.authors,
        question: userQuery,
        email: user?.email,
      });
    trackEvent({
      email: getUsersEmail(user),
      eventCategory: EventCategory.CHATBOT,
      event: ChatBotEvents.USER_ASKED_QUESTION_AT_PUBLIC_CHATBOT,
      subCategory: SubCategory.EVENT,
      source: Source.PUBLIC,
      bookId: bookId,
      rawData: {
        question: userQuery,
      },
    });

    const requestBody = {
      question: userQuery,
      bookId: bookId,
      source: ChatSource.public,
    };

    const chat = new Chat(
      requestBody,
      (token: string) => {
        setCurrentSessionHistory((oldQuestions: any[]) => {
          if (oldQuestions.length > 0) {
            oldQuestions[oldQuestions.length - 1].answer = oldQuestions[
              oldQuestions.length - 1
            ].answer
              ? oldQuestions[oldQuestions.length - 1].answer + token
              : token;
            return [...oldQuestions];
          } else return [];
        });
      },
      (data: any) => {
        getFullAnswer(data, userQuery);
      },
      (data: any) => {
        NotificationManager.error(data);
      },
      ""
      // user?.jwt
    );

    await chat.startChat();
  };

  const submitQuestion = (values: any, actions: any) => {
    actions.resetForm({
      values: {
        prompt: "",
      },
    });
    chatWithDropchat(values.prompt);
  };

  return (
    <>
      <BookChatStyles.MainContainer fluid>
        {deviceWidth === DEVICE_MEDIUM && (
          <PublicQuestionsSideBar
            questionAnswerHistory={allSessionHistory}
            setCurrentSessionHistory={setCurrentSessionHistory}
          />
        )}
        <BookChatStyles.RightColumn
          xs={deviceWidth === DEVICE_MOBILE ? 12 : 10}
        >
          <BookChatStyles.ChatPageHeader className="App-header">
            <Col sm={11}>
              <BookChatStyles.BackButton
                src={BackIcon}
                onClick={() => {
                  navigate("/");
                }}
                width={deviceWidth === DEVICE_MOBILE ? "10" : "15"}
                style={{ marginRight: "1rem" }}
              />
              <img
                width={deviceWidth === DEVICE_MOBILE ? "15" : "30"}
                style={{ marginRight: "1rem" }}
                src={
                  localBook?.imageUrl
                    ? localBook?.imageUrl
                    : localBook?.fileType === "audio/mpeg"
                    ? MP3Sign
                    : localBook?.fileType === "audio/ogg"
                    ? OGGSign
                    : localBook?.fileType === "audio/wav"
                    ? WAVSign
                    : localBook?.fileType === "audio/flac"
                    ? FLACSign
                    : localBook?.fileType === "video/mp4"
                    ? MP4Sign
                    : localBook?.fileType === "application/epub+zip"
                    ? EPUBSign
                    : localBook?.fileType === "md"
                    ? MDSign
                    : localBook?.fileType === "mdx"
                    ? MDXSign
                    : localBook?.fileType === "text/plain"
                    ? TXTSign
                    : localBook?.fileType === "text/html"
                    ? HTMLSign
                    : localBook?.fileType === "youtube"
                    ? YouTubeSign
                    : localBook?.fileType === "url"
                    ? URLSign
                    : localBook?.fileType === "application/pdf"
                    ? PDFSign
                    : localBook?.fileType === "text"
                    ? TextSign
                    : ""
                }
                alt="book_image"
              />
              <BookChatStyles.BookContent>
                <h5
                  style={{
                    margin: 0,
                    color: "#33303c",
                    fontSize:
                      deviceWidth === DEVICE_MOBILE ? "0.75rem" : "1rem",
                  }}
                >
                  {localBook && localBook.title}
                </h5>
                <h6
                  style={{
                    color: "rgba(51, 48, 60, 0.6)",
                    fontSize:
                      deviceWidth === DEVICE_MOBILE ? "0.75rem" : "1rem",
                  }}
                >
                  {localBook &&
                    localBook.authors &&
                    localBook.authors.length > 0 &&
                    localBook.authors.map((author: any) => author).join(", ")}
                </h6>
              </BookChatStyles.BookContent>
            </Col>

            <BookChatStyles.Column sm={3}></BookChatStyles.Column>
          </BookChatStyles.ChatPageHeader>
          <BookChatStyles.ChatInputBox sm={12}>
            <BookChatStyles.RightContentRow>
              <BookChatStyles.ChatBotContent sm={12}>
                <BookChatStyles.ScrollDiv>
                  {localBook.indexStatus !== 2 &&
                  (localBook.type === "custom" ||
                    localBook.chatType === "dropchat") ? (
                    <BookChatStyles.StatusLoaderSection>
                      <img src={LoaderGif} alt="loading..." width={60} />
                      <BookChatStyles.WaitTitle>
                        Please Wait
                      </BookChatStyles.WaitTitle>
                      <BookChatStyles.WaitDescription>
                        {fileProcessingMessage &&
                        fileProcessingMessage.length > 0
                          ? fileProcessingMessage
                          : ` Hello! We wanted to let you know that it will take a few
                        minutes to process this item. Don't worry, though - once
                        the file processing is finished, you'll be able to ask
                        any questions you have. You can leave this screen and
                        return a few minutes later to check status. Or you can
                        wait for status to chance to “Ready” on main page.`}
                      </BookChatStyles.WaitDescription>
                    </BookChatStyles.StatusLoaderSection>
                  ) : (
                    <BookChatStyles.QuestionAnswerSection>
                      {loading && (
                        <BookChatStyles.StatusLoaderSection>
                          <img src={LoaderGif} alt="loading..." width={60} />
                          <BookChatStyles.WaitTitle>
                            Please Wait
                          </BookChatStyles.WaitTitle>
                        </BookChatStyles.StatusLoaderSection>
                      )}

                      {currentSessionHistory &&
                        currentSessionHistory.length > 0 &&
                        currentSessionHistory.map((data: any) => (
                          <BookChatStyles.SignleQuestionAnswerSection>
                            <BookChatStyles.QuestionSection>
                              <img
                                width={
                                  deviceWidth === DEVICE_MOBILE ? "15" : "30"
                                }
                                src={UserImage}
                                alt="user_image"
                              />
                              &nbsp;&nbsp;
                              <h5
                                style={{
                                  fontSize:
                                    deviceWidth === DEVICE_MOBILE
                                      ? "0.75rem"
                                      : "1rem",
                                }}
                              >
                                {data.question}
                              </h5>
                            </BookChatStyles.QuestionSection>
                            <BookChatStyles.ResponseSection>
                              <div>
                                <img
                                  width={
                                    deviceWidth === DEVICE_MOBILE ? "15" : "30"
                                  }
                                  src={ChatBotIcon}
                                  alt="user_image"
                                  style={{
                                    display: "flex",
                                    alignSelf: "baseline",
                                  }}
                                />
                                &nbsp;&nbsp;
                                {data.answer && data.answer.length > 0 ? (
                                  <BookChatStyles.MarkDownContent>
                                    <CustomMarkdown text={data.answer} />
                                  </BookChatStyles.MarkDownContent>
                                ) : (
                                  <h5 style={{ margin: 0 }}>
                                    {`${data.answer}`}
                                    {data._id ? null : (
                                      <img
                                        width={
                                          deviceWidth === DEVICE_MOBILE
                                            ? "15"
                                            : "30"
                                        }
                                        src={TypingLoading}
                                        alt="..."
                                      />
                                    )}
                                  </h5>
                                )}
                              </div>
                            </BookChatStyles.ResponseSection>
                          </BookChatStyles.SignleQuestionAnswerSection>
                        ))}
                    </BookChatStyles.QuestionAnswerSection>
                  )}
                </BookChatStyles.ScrollDiv>
              </BookChatStyles.ChatBotContent>
              <BookChatStyles.FieldColumn sm={12}>
                {(showOverlay ||
                  (localBook.chatType === "dropchat" &&
                    localBook.indexStatus !== 2)) && (
                  <BookChatStyles.OverlayFieldColumn
                    sm={12}
                  ></BookChatStyles.OverlayFieldColumn>
                )}

                <Formik
                  initialValues={{
                    prompt: questionValue,
                  }}
                  onSubmit={(values, actions) => {
                    setQuestionValue("");
                    submitQuestion(values, actions);
                  }}
                  enableReinitialize
                >
                  {({ errors, touched, isValidating }: any) => (
                    <BookChatStyles.BookFetchForm
                      ref={(el) => setFormRef(el)}
                      style={{ padding: "0", paddingBottom: "6px" }}
                      className={PUBLIC_USER_CHATBOT}
                    >
                      <BookChatStyles.FieldSection>
                        <BookChatStyles.FormInput
                          name="prompt"
                          as="textarea"
                          value={questionValue}
                          placeholder={
                            'Ask me anything from "' + localBook.title + '"'
                          }
                          onChange={(e) => setQuestionValue(e.target.value)}
                          onKeyDown={(e) => {
                            // eslint-disable-next-line
                            if (e.keyCode == 13 && e.shiftKey == false) {
                              e.preventDefault();
                              formRef.requestSubmit();
                            }
                          }}
                          style={{
                            paddingRight: "2.6rem",
                            resize: "none",
                            height: "46px",
                            padding: "11px",
                          }}
                        />
                        <BookChatStyles.FetchBookButton
                          type="submit"
                          style={{ top: "10px" }}
                        >
                          <img width={25} src={SearchArrow} alt="search" />
                        </BookChatStyles.FetchBookButton>
                      </BookChatStyles.FieldSection>
                    </BookChatStyles.BookFetchForm>
                  )}
                </Formik>
              </BookChatStyles.FieldColumn>
            </BookChatStyles.RightContentRow>
          </BookChatStyles.ChatInputBox>
        </BookChatStyles.RightColumn>

        {showLoginPopup && (
          <>
            <Modal show={true} backdrop="static" keyboard={false}>
              <MagicLinkLoginPopup showLoginPopup={showLoginPopup} />
            </Modal>
          </>
        )}
      </BookChatStyles.MainContainer>
    </>
  );
};

export default TeamBookChat;
