// import { OverlayPanel } from "primereact/overlaypanel";
import { useContext, useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import ProgressBar from "react-bootstrap/ProgressBar";
// import { CopyToClipboard } from "react-copy-to-clipboard";
// import { NotificationManager } from "react-notifications";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { MEDIUM } from "../../constants/desktop";
import { MOBILE } from "../../constants/mobile";
import { TAB } from "../../constants/tab";
import { BookContext } from "../../context/BookContext";
// import { UserContext } from "../../context/UserContext";
import { CodeBoxContext } from "../../context/code-box-context";
import { LoaderContext } from "../../context/loader-context";
import { PaginationContext } from "../../context/pagination-context";
// import { PlanContext } from "../../context/plan-context";
// import { USER_CREATED_A_SHARE_URL_CHATBOT } from "../../customer-io/events";
// import codeembad from "../../images/code.svg";
// import CopyIframe from "../../images/copyIframe.svg";
// import LinkCopy from "../../images/link.svg";
import ProgressIndicator from "../../images/loading_bar.gif";
import PdfUrl from "../../images/pdf-url.svg";
import PdfYoutubeUrl from "../../images/pdf-youtube-url.svg";
import PdfYoutube from "../../images/pdf-youtube.svg";
import PDFSign from "../../images/pdfSign.svg";
// import DeleteIconDisabled from "../../images/trash-disabled.svg";
// import Trash from "../../images/trash-normal.svg";
import TextSign from "../../images/txtSign.svg";
import URLSign from "../../images/urlSign.svg";
import YoutubeUrl from "../../images/youtube-url.svg";
import YouTubeSign from "../../images/youtube.svg";
import myAxios from "../../myaxios";
import { Book } from "../../types";
// import { isTestUser } from "../../utils/utils";
import Pagination from "../pagination/pagination";
import AddMoreFileBox from "../utility-component/add-more-file-box";
import CodeBox from "../utility-component/code-box";
// import ConfirmationBox from "../utility-component/confirmation-box";
import { TeamContext } from "../../context/team-context";

// declare var _cio: any;

const BookImage = styled.img`
  /* width: 100%; */
  box-shadow: 2px 2px 10px -4px #000000bd;
  margin-bottom: 5px;
  border-radius: 10px;
  min-height: 100%;
  min-width: 100%;
  object-fit: cover;
`;
const ImageDiv = styled.div`
  width: 100%;
  height: 230px;
  @media (max-width: ${MOBILE.maxWidth}px) {
    width: 100%;
    height: 214px;
  }
`;

const BookListParent = styled(Col)`
  flex-direction: column;
  white-space: break-spaces;
  transition: 0.4s ease;
  position: relative;
  & p {
  }
  & a {
    text-decoration: none;
  }
`;

const SingleBookTitle = styled.p`
  width: 100%;
  color: #33303c;
  margin-bottom: 10px;
  font-size: 16px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  @media (max-width: ${MOBILE.maxWidth}px) {
    font-size: 13px;
    font-weight: 400;
    white-space: normal;
  }
  @media (min-width: ${TAB.minWidth}px) {
    white-space: normal;
  }
  @media (min-width: ${MEDIUM.minWidth}px) {
    white-space: nowrap;
  }
`;

const BookActions = styled.div`
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 0.3rem 0;
  & a {
    display: flex;
  }
`;

const IndexStatus = styled.span`
  background: ${(props: BackgroundColor) => props.background};
  color: #fff;
  padding: 2px 5px;
  border-radius: 5px;
  position: absolute;
  top: 0.2rem;
  right: 1rem;
  font-size: 10px;
  text-align: center;
`;

const PaginationRow = styled(Row)`
  justify-content: center;
  width: 100%;
  margin-top: 2rem;
  width: 100%;
  align-items: end;
  border-radius: 4px;
  & .col {
    text-align: center;
  }
`;

// const BookActionUl = styled.ul`
//   list-style: none;
//   margin: 0;
//   padding: 0;
//   display: flex;
//   position: relative;
//   cursor: pointer;
//   justify-content: end;
// `;
// const BookActionLi = styled.li`
//   display: inline-block;
//   border-radius: 50%;
//   background: #1585b5;
//   margin: 0px 1.5px;
//   padding: 2.5px;
//   height: 6px;
// `;

// const OverlayPanelStyle = styled(OverlayPanel)`
//   flex-direction: column;
// `;

// const AddCategoryHeading = styled.span`
//   font-weight: 600;
//   padding-right: 1.25rem;
//   padding-top: 15px;
//   padding-bottom: 15px;
//   cursor: pointer;
//   font-style: normal;
//   font-weight: 400;
//   font-size: 16px;
//   line-height: 10px;
//   color: #585858;
//   &:hover {
//     background: #d8e4eb;
//   }
// `;

// const AddCategoryHeadingDisabled = styled.span`
//   color: rgba(88, 88, 88, 0.3);
//   padding-right: 1.25rem;
//   padding-top: 15px;
//   padding-bottom: 5px;
//   font-style: normal;
//   font-weight: 400;
//   font-size: 16px;
//   line-height: 10px;
// `;

// const UpgradeSpan = styled.span`
//   display: flex;
//   flex-direction: row;
//   align-items: center;
//   margin-top: 0px;
//   padding: 5px 25px;
//   background: #f2ae01;
//   border-radius: 4px;
//   font-style: normal;
//   font-weight: 600;
//   font-size: 14px;
//   color: #ffffff;
//   cursor: pointer;
// `;

const ProgressBarSpan = styled.span`
  font-size: 12px;
  color: #33303c99;
  font-weight: 600;
`;

interface BackgroundColor {
  background: string;
}

// const BookOption = ({
//   book,
//   deleteBook,
//   indexing,
//   setSelectedChatbot,
// }: any) => {
//   const { setBookId, setShowConfirmationBox, setShowIframeCodeBox } =
//     useContext(CodeBoxContext);
//   const { user } = useContext(UserContext);
//   const { setPlanHighliter } = useContext(PlanContext);
//   const navigate = useNavigate();
//   const op = useRef<any>(null);
//   const [showUtilityConfirmationBox, setShowUtilityConfirmationBox] =
//     useState<boolean>(false);
//   const [confirmationBoxMessage, setConfirmationBoxMessage] =
//     useState<string>("");
//   const [confirmationAction, setConfirmationAction] = useState<string>("");

//   const deleteConfirmationBox = () => {
//     setConfirmationBoxMessage("Do you really want to delete this chatbot?");
//     setConfirmationAction("delete");
//     setShowUtilityConfirmationBox(true);
//   };

//   const confirmClick = (action: string) => {
//     if (action === "delete") {
//       deleteBook(book);
//     }
//   };

//   return (
//     <>
//       <div>
//         <BookActionUl onClick={(e) => op.current.toggle(e)}>
//           <BookActionLi></BookActionLi>
//           <BookActionLi></BookActionLi>
//           <BookActionLi></BookActionLi>
//         </BookActionUl>

//         <div
//           style={{
//             display: "flex",
//             justifyContent: "end",
//             backgroundColor: "#1585b5",
//           }}
//         >
//           <OverlayPanelStyle
//             ref={op}
//             style={{
//               background: "white",
//             }}
//           >
//             {indexing === 1 ? (
//               <AddCategoryHeading
//                 onClick={(e) => {
//                   op.current.toggle(e);
//                   deleteConfirmationBox();
//                 }}
//                 style={{
//                   marginBottom: "-5px",
//                 }}
//               >
//                 <img
//                   alt="img1"
//                   src={Trash}
//                   width="16"
//                   height="16"
//                   style={{
//                     marginLeft: "10px",
//                     marginRight: "7px",
//                     marginBottom: "4px",
//                   }}
//                 />
//                 Delete chatbot
//               </AddCategoryHeading>
//             ) : (
//               <>
//                 {user?.currentSubscription ? (
//                   user?.currentSubscription.meta?.deleteChat ? (
//                     <AddCategoryHeading
//                       onClick={(e) => {
//                         op.current.toggle(e);
//                         deleteConfirmationBox();
//                       }}
//                       style={{
//                         marginBottom: "-5px",
//                       }}
//                     >
//                       <img
//                         alt="img2"
//                         src={Trash}
//                         width="16"
//                         height="16"
//                         style={{
//                           marginLeft: "10px",
//                           marginRight: "7px",
//                           marginBottom: "4px",
//                         }}
//                       />
//                       Delete chatbot
//                     </AddCategoryHeading>
//                   ) : (
//                     <AddCategoryHeadingDisabled>
//                       <img
//                         alt="img3"
//                         src={DeleteIconDisabled}
//                         width="16"
//                         height="16"
//                         style={{
//                           marginLeft: "10px",
//                           marginRight: "7px",
//                           marginBottom: "5px",
//                         }}
//                       />
//                       Delete chatbot
//                       <div style={{ paddingLeft: "2rem" }}>
//                         <UpgradeSpan
//                           onClick={(e) => {
//                             op.current.toggle(e);
//                             setPlanHighliter({
//                               isBookHighliter: false,
//                               bookLimit: 0,
//                               isDeleteBookHighliter: true,
//                               isFlashcardHighliter: false,
//                               isCharacterLimit: false,
//                               pdfLimit: 0,
//                             });
//                             navigate("/subscription");
//                           }}
//                         >
//                           Upgrade Plan
//                         </UpgradeSpan>
//                         {/* <PlanSpan>Your plan</PlanSpan> */}
//                       </div>
//                     </AddCategoryHeadingDisabled>
//                   )
//                 ) : (
//                   <AddCategoryHeadingDisabled>
//                     <img
//                       alt="img3"
//                       src={DeleteIconDisabled}
//                       width="16"
//                       height="16"
//                       style={{
//                         marginLeft: "10px",
//                         marginRight: "7px",
//                         marginBottom: "5px",
//                       }}
//                     />
//                     Delete chatbot
//                     <div style={{ paddingLeft: "2rem" }}>
//                       <UpgradeSpan
//                         onClick={(e) => {
//                           op.current.toggle(e);
//                           setPlanHighliter({
//                             isBookHighliter: false,
//                             bookLimit: 0,
//                             isDeleteBookHighliter: true,
//                             isFlashcardHighliter: false,
//                             isCharacterLimit: false,
//                             pdfLimit: 0,
//                           });
//                           navigate("/subscription");
//                         }}
//                       >
//                         Purchase Plan
//                       </UpgradeSpan>
//                       {/* <PlanSpan>Plan</PlanSpan> */}
//                     </div>
//                   </AddCategoryHeadingDisabled>
//                 )}
//               </>
//             )}
//             {indexing === 2 ? (
//               <>
//                 {/* {book.type === "aws" && book.fileType === "application/pdf" && (
//                   <AddCategoryHeading
//                     onClick={(e) => {
//                       op.current.toggle(e);
//                       setSelectedChatbot(book);
//                       setShowAddMoreFileBox(true);
//                     }}
//                   >
//                     <img
//                       alt="img4"
//                       src={ADDSOURCEIMG}
//                       width="16"
//                       height="16"
//                       style={{
//                         marginLeft: "10px",
//                         marginRight: "7px",
//                       }}
//                     />
//                     Add Source
//                   </AddCategoryHeading>
//                 )} */}
//                 <AddCategoryHeading
//                   onClick={(e) => {
//                     op.current.toggle(e);
//                     setBookId(book._id);
//                     setShowConfirmationBox(true);
//                   }}
//                 >
//                   <img
//                     alt="img4"
//                     src={codeembad}
//                     width="16"
//                     height="16"
//                     style={{
//                       marginLeft: "10px",
//                       marginRight: "7px",
//                     }}
//                   />
//                   Embed
//                 </AddCategoryHeading>
//                 <AddCategoryHeading>
//                   <CopyToClipboard
//                     text={`${process.env.REACT_APP_FRONTEND_URL}/public/shared-chat/${book._id}`}
//                     onCopy={(e) => {
//                       NotificationManager.success("Chatbot URL copied.");
//                       if (!isTestUser(user?.email))
//                         _cio.track(USER_CREATED_A_SHARE_URL_CHATBOT, {
//                           name: "user-created-a-share-url-chatbot",
//                         });
//                       try {
//                         op.current.toggle(e);
//                       } catch (error) {
//                         // console.log(
//                         //   "Getting error at the time of copy id ",
//                         //   error
//                         // );
//                       }
//                     }}
//                   >
//                     <span>
//                       <img
//                         alt="img5"
//                         src={LinkCopy}
//                         width="16"
//                         height="16"
//                         style={{
//                           marginLeft: "10px",
//                           marginRight: "7px",
//                         }}
//                       />
//                       Share Chatbot URL
//                     </span>
//                   </CopyToClipboard>
//                 </AddCategoryHeading>
//                 <AddCategoryHeading
//                   onClick={(e) => {
//                     op.current.toggle(e);
//                     setBookId(book._id);
//                     setShowIframeCodeBox(true);
//                   }}
//                 >
//                   <img
//                     alt="img4"
//                     src={CopyIframe}
//                     width="16"
//                     height="16"
//                     style={{
//                       marginLeft: "10px",
//                       marginRight: "7px",
//                     }}
//                   />
//                   Copy iFrame
//                 </AddCategoryHeading>
//               </>
//             ) : null}
//           </OverlayPanelStyle>
//         </div>
//         {showUtilityConfirmationBox && (
//           <ConfirmationBox
//             setShow={setShowUtilityConfirmationBox}
//             name="Confirmation"
//             message={confirmationBoxMessage}
//             okButtonText="Yes"
//             action={confirmationAction}
//             okButtonClick={confirmClick}
//           />
//         )}
//       </div>
//     </>
//   );
// };

const BookLibrary = ({
  allBooks,
  setTotalPage,
  totalPage,
  resetPagination,
  setResetPagination,
  isUserCanChange,
}: any) => {
  const { setShowLoader } = useContext(LoaderContext);
  const { selectedTeam } = useContext(TeamContext);
  // eslint-disable-next-line
  const [selectedChatbot, setSelectedChatbot] = useState();
  const { setBooks, setSelectedBook, setNoOfBots } = useContext(BookContext);
  // const { user, setTotalBooksCount } = useContext(UserContext);
  const { pageLimit, setPageLimit, pageSkip } = useContext(PaginationContext);
  const { showConfirmationBox } = useContext(CodeBoxContext);

  const pageChange = (currentPage: any) => {
    const skip = (currentPage - 1) * pageLimit;

    setShowLoader(true);
    myAxios
      .post(process.env.REACT_APP_SERVER_URL + "/books/paginated-books", {
        pagination: { limit: pageLimit, skip: skip },
        team: selectedTeam?._id,
      })
      .then((response) => {
        setBooks(response.data.data.books);
        setTotalPage(response.data.data.count);
        setNoOfBots(response.data.data.count);
        setShowLoader(false);
      })
      .catch((error) => {
        // console.log("Getting Error at the time of get paginated books ", error);
        setShowLoader(false);
      });
  };
  const pageLimitChange = (pageLimit: number) => {
    setPageLimit(pageLimit);
    myAxios
      .post(process.env.REACT_APP_SERVER_URL + "/books/paginated-books", {
        pagination: { limit: pageLimit, skip: pageSkip },
        team: selectedTeam?._id,
      })
      .then((response) => {
        setBooks(response.data.data.books);
        setTotalPage(response.data.data.count);
        setNoOfBots(response.data.data.count);
      })
      .catch((error) => {
        // console.log("Getting Error at the time of get paginated books ", error);
      });
  };

  useEffect(() => {
    if (showConfirmationBox) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }
  }, [showConfirmationBox]);

  const bookImageURL = (book: Book): string => {
    // Check if book has an image URL
    if (book.imageUrl && book.imageUrl.length > 0) {
      return book.imageUrl;
    }

    // Default image URL is PDFSign
    let imageUrl = PDFSign;

    // Determine if the book is PDF, URL, or YouTube
    let isPdf = false;
    let isUrl = false;
    let isYT = false;

    // Iterate over sources to determine types
    book.sources?.forEach((source: any) => {
      switch (source.type) {
        case "aws":
          isPdf = true;
          break;
        case "url":
          isUrl = true;
          break;
        case "youtube":
          isYT = true;
          break;
        case "text":
          imageUrl = TextSign;
          break;
        case "character":
          imageUrl = TextSign;
          break;
      }
    });

    // Set image URL based on source types
    if (isPdf && isYT && isUrl) {
      imageUrl = PdfYoutubeUrl;
    } else if (isPdf && isYT && !isUrl) {
      imageUrl = PdfYoutube;
    } else if (isPdf && !isYT && isUrl) {
      imageUrl = PdfUrl;
    } else if (!isPdf && isYT && isUrl) {
      imageUrl = YoutubeUrl;
    } else if (!isPdf && isYT && !isUrl) {
      imageUrl = YouTubeSign;
    } else if (isPdf && !isYT && !isUrl) {
      imageUrl = PDFSign;
    } else if (!isPdf && !isYT && isUrl) {
      imageUrl = URLSign;
    }

    return imageUrl;
  };

  const getFileStatus = (status: number) => {
    if (status === 1) {
      return "UNDER PROCESSING";
    } else if (status === 2) {
      return "READY";
    } else if (status === 3) {
      return "FAILED";
    } else {
      return "UNDER PROCESSING";
    }
  };
  return (
    <>
      {allBooks &&
        allBooks.length > 0 &&
        allBooks.map((book: any, idx: any) => (
          <BookListParent
            key={Math.random()}
            lg={isUserCanChange ? 3 : 2}
            md={isUserCanChange ? 4 : 3}
            xs={6}
            onClick={() => {
              setSelectedBook(allBooks[idx]);
            }}
          >
            <Link
              to={
                isUserCanChange
                  ? "/books/" + book._id + "/chatbot-settings/chat"
                  : "/team/" + book._id
              }
            >
              <ImageDiv>
                <BookImage src={bookImageURL(book)} />
              </ImageDiv>
            </Link>
            <IndexStatus
              background={
                book.chatType === "dropchat"
                  ? book.indexStatus === 1
                    ? "#F49040"
                    : book.indexStatus === 2
                    ? "#21CA51"
                    : book.indexStatus === 3
                    ? "#EA4335"
                    : "#F49040"
                  : "#21CA51"
              }
            >
              {book.chatType === "dropchat"
                ? getFileStatus(book.indexStatus)
                : "READY"}
            </IndexStatus>

            {book.indexStatus === 1 &&
            (book.type === "aws" ||
              book.type === "url" ||
              book.type === "youtube") ? (
              <>
                <BookActions
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    padding: "0px",
                  }}
                >
                  {book.chatbotId ? (
                    <>
                      {" "}
                      <ProgressBarSpan>Processing</ProgressBarSpan>
                      <ProgressBarSpan>
                        {book.roundProgressPercentage}%
                      </ProgressBarSpan>
                    </>
                  ) : (
                    <ProgressBarSpan>initializing....</ProgressBarSpan>
                  )}
                </BookActions>
              </>
            ) : (
              <BookActions></BookActions>
            )}

            <Link
              to={
                isUserCanChange
                  ? "/books/" + book._id + "/chatbot-settings/chat"
                  : "/team/" + book._id
              }
            >
              {book.indexStatus === 1 &&
              (book.type === "aws" ||
                book.type === "url" ||
                book.type === "youtube") ? (
                <>
                  {book.roundProgressPercentage < 1 ? (
                    <div>
                      <img
                        alt="img6"
                        src={ProgressIndicator}
                        style={{ width: "100%" }}
                      />
                    </div>
                  ) : (
                    <ProgressBar
                      animated
                      variant={
                        book.roundProgressPercentage < 34
                          ? "warning"
                          : book.roundProgressPercentage < 68
                          ? "info"
                          : "success"
                      }
                      now={book.roundProgressPercentage}
                      style={{ height: "8px" }}
                    />
                  )}
                </>
              ) : null}

              {book.title ? (
                <SingleBookTitle title={book.title}>
                  <b>Title: </b>
                  {book.title}
                </SingleBookTitle>
              ) : (
                <SingleBookTitle>
                  <b>No Title </b>
                </SingleBookTitle>
              )}
              {book.authors && book.authors[0].length > 0 && (
                <SingleBookTitle
                  style={{ fontSize: "14px", color: "rgba(51, 48, 60, 0.6)" }}
                  title={book.authors}
                >
                  <b>Authors: </b>
                  {book.authors?.map((item: any) => item + "\n")}
                </SingleBookTitle>
              )}
            </Link>
          </BookListParent>
        ))}

      {totalPage && totalPage > 8 && (
        <PaginationRow>
          <Pagination
            itemCount={totalPage}
            perPageItem={pageLimit}
            pageChange={pageChange}
            pageLimitChange={pageLimitChange}
            resetPagination={resetPagination}
            pageLimit={pageLimit}
            setResetPagination={setResetPagination}
          />
        </PaginationRow>
      )}
      <CodeBox />
      <AddMoreFileBox
        chatbot={selectedChatbot}
        pageLimitChange={pageLimitChange}
        pageLimit={pageLimit}
      />
    </>
  );
};

export default BookLibrary;
