import { Container } from "react-bootstrap";
import styled from "styled-components";
import { MOBILE } from "../../constants/breakpoints";
import FormBackground from "../../images/landingAssets/formBackground.svg";
import MainFooter from "../footer";
import MainHeader from "../header";
// import DefaultBooks from "./defaultBooks";
// import BookFunctionality from "./waiting-section";
import BookFunctionalityWithLoginPage from "./book-functionality-login-page";
// import { AdminContext } from "../../context/admin-context";
import { useContext, useEffect, useState } from "react";
// import myAxios, { setAuthHeader } from "../../myaxios";
import axios from "axios";
import BookFunctionalityTrialPage from "./book-functionality-trial-page";
import { LoaderContext } from "../../context/loader-context";

const MainContainer = styled(Container)`
  /* min-height: 500px; */
  background-size: cover;
  flex-direction: column;
`;

const BookFunctionalityParent = styled(Container)`
  /* background-image: url("${FormBackground}"); */
  background-size: cover;
  flex-direction: column;
  @media (max-width: ${MOBILE.maxWidth}px) {
    background-image: url("${""}");
  }

  @media (min-width: 320px) and (max-width: 450px) {
    background-size: contain;
  }
`;

const DefaultBooksParent = styled(Container)`
  flex: 1;
`;

const LandingPage = () => {
  const [isBeforeLoginTrial, setIsBeforeLoginTrial] = useState(false);
  const { setShowLoader } = useContext(LoaderContext);
  useEffect(() => {
    setShowLoader(true);
    setTimeout(() => {
      axios
        .get(process.env.REACT_APP_SERVER_URL + "/public/getLandingBeforeTrial")
        .then((response) => {
          if (response.data) {
            setIsBeforeLoginTrial(response.data.message.isLoginbeforetrial);
            setShowLoader(false);
          }
        })
        .catch((error) => {
          // handle error
        });
    }, 300);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const script = document.createElement("script");
    script.async = true;
    script.src = "https://www.usetiful.com/dist/usetiful.js";
    script.setAttribute("id", "usetifulScript");
    script.dataset.token = process.env.REACT_APP_USETIFUL_TOKEN;

    document.head.appendChild(script);

    return () => {
      // Cleanup the script if the component unmounts
      document.getElementById("usetifulScript")?.remove();
    };
  }, []); // Empty dependency array ensures this runs only once on mount

  return (
    <>
      <MainContainer fluid className="p-0">
        <MainHeader landing={false} isBeforeLoginTrial={isBeforeLoginTrial} />
        <BookFunctionalityParent fluid>
          {/* <BookFunctionality /> */}

          {isBeforeLoginTrial ? (
            <BookFunctionalityWithLoginPage
              isBeforeLoginTrial={isBeforeLoginTrial}
            />
          ) : (
            <BookFunctionalityTrialPage />
          )}
        </BookFunctionalityParent>
      </MainContainer>
      <DefaultBooksParent fluid>{/* <DefaultBooks /> */}</DefaultBooksParent>
      <MainFooter />
    </>
  );
};

export default LandingPage;
