import axios from "axios";
import { Field, Form, Formik } from "formik";
import { AutoComplete } from "primereact/autocomplete";
import { useContext, useEffect, useState } from "react";
import { Button, Col, FormLabel, Row } from "react-bootstrap";
import { NotificationManager } from "react-notifications";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { MEDIUM, MOBILE, TAB } from "../../constants/breakpoints";
import { PublicContext } from "../../context/PublicContext";
import { ChooseURLContext } from "../../context/choose-url-context";
import { LoaderContext } from "../../context/loader-context";
import {
  GUEST_USER_CREATED_A_CHATBOT,
  USER_ADD_A_BOOK,
} from "../../customer-io/events";
import socket from "../../dropchat-socket"; //"../../dropchat-socket";
import {
  ChatBotEvents,
  EventCategory,
  Source,
  SubCategory,
} from "../../event-utils/dropchat-event-enums";
import { trackEvent } from "../../event-utils/track-event";
import { Mixpanel } from "../../mixpanel";
// import { CREATE_PUBLIC_BOOK } from "../../utils/class-names";
import {
  isTestUser,
  matchYoutubeUrl,
  parseErrorResponse,
} from "../../utils/utils";
import UrlSelectionBox from "../utility-component/url-selection-box";
declare var _cio: any;

// const BookFetchForm = styled(Form)`
//   margin: auto;
//   width: 100%;
//   margin: 0;
// `;

const FieldSection = styled.div`
  margin-bottom: 10px;
  flex-direction: column;
  & label {
    @media (max-width: ${MOBILE.maxWidth}px) {
      font-size: 12px;
    }
    @media (min-width: ${TAB.minWidth}px) {
      font-size: 14px;
    }
    @media (min-width: ${MEDIUM.minWidth}px) {
      font-size: 16px;
    }
  }
`;

const FormError = styled.div`
  margin: 0rem 0rem 1rem;
  text-align: left;
  color: red;
  font-size: 13px;
  font-family: "Poppins", sans-serif;
`;

const FormInput = styled(Field)`
  /* width: 80%; */
  width: 100%;
  padding: 8px;
  border: 1px solid rgba(201, 199, 206, 1);
  border-radius: 4px;
  @media (max-width: ${MOBILE.maxWidth}px) {
    font-size: 12px;
    width: 100%;
  }
  @media (min-width: ${TAB.minWidth}px) {
    font-size: 14px;
  }
  @media (min-width: ${MEDIUM.minWidth}px) {
    font-size: 16px;
  }
`;

const SaveBookButton = styled(Button)`
  width: 98%;
  background: #1585b5;
  padding: 0.5rem;
  color: #fff;
  margin-right: 0.5rem;
  border-radius: 4px;
  &:hover {
    background: #0b5f82;
  }
`;

const CancelBookButton = styled(Button)`
  background: #ffc720;
  border-color: #ffc720;
  width: 98%;
  margin-left: 0.5rem;
`;

const SaveBookButtonDisbaled = styled(Button)`
  width: 40%;
  background: rgba(21, 133, 181, 0.2);
  padding: 0.5rem;
  color: #fff;
  border-radius: 4px;
`;
const FormInputTextArea = styled.textarea`
  width: 80%;
  padding: 10px;
  height: 5rem;
`;
const FormLabelDocInput = styled(FormLabel)`
  background: #ccc;
  width: 40%;
  padding: 5px 10px;
  border-radius: 4px;
  border: 1px solid #1585b5;
  background: linear-gradient(
    0deg,
    rgba(21, 133, 181, 0.2),
    rgba(21, 133, 181, 0.2)
  );
  display: flex;
  align-items: center;
  justify-content: center;
  color: #33303ccc;
  font-weight: 500;
  cursor: pointer;
`;

const MainLabel = styled.label`
  display: flex;
  cursor: pointer;
  @media (max-width: ${MOBILE.maxWidth}px) {
    margin-left: 5px;
    padding-left: 5px;
  }
  @media (min-width: ${TAB.minWidth}px) {
    margin-left: 5px;
    padding-left: 5px;
  }
  @media (min-width: ${MEDIUM.minWidth}px) {
    margin-left: 20px;
    padding-left: 10px;
  }
`;

const FormLabelSpan = styled.span`
  font-size: 14px;
  color: rgba(34, 51, 34, 1);
  line-height: 16px;
  font-family: "Poppins", sans-serif;
  @media (max-width: ${MOBILE.maxWidth}px) {
    margin-left: 2px;
    font-size: 12px;
  }
  @media (min-width: ${TAB.minWidth}px) {
    margin-left: 4px;
    font-size: 14px;
  }
  @media (min-width: ${MEDIUM.minWidth}px) {
    margin-left: 4px;
    font-size: 14px;
  }
`;
const FieldS = styled(Field)`
  cursor: pointer;
`;
const FormLabels = styled(FormLabel)`
  color: rgba(51, 48, 60, 0.8);
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  font-family: "Poppins", sans-serif;
  margin-bottom: auto;
`;

const CreateBook = ({ setBookId, bookId }: any) => {
  const {
    setShowConfirmationBox,
    setUrlList,
    setCreateBookInput,
    setResetForm,
  } = useContext(ChooseURLContext);
  const { indexingProgress, setIndexingProgress, setBookTypeName } =
    useContext(PublicContext);
  const [selectedOption, setSelectedOption] = useState<any>("");
  const { setShowLoader } = useContext(LoaderContext);

  const navigate = useNavigate();

  const [fileObject, setFileObject] = useState<any>();

  const isValidHttpUrl = (urlString: string) => {
    let url;
    try {
      url = new URL(urlString);
    } catch (_) {
      return false;
    }
    return url.protocol === "http:" || url.protocol === "https:";
  };

  const validateUrl = (value: string) => {
    return /^(?:(?:(?:https?|ftp|http):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:[/?#]\S*)?$/i.test(
      value
    );
  };

  const validateBookSubmission = (formData: any) => {
    const errors: any = {};

    // console.log("fileObject is, ", fileObject);

    if (
      formData.selectedRadio === "book" ||
      formData.selectedRadio === "character"
    ) {
      if (!formData.title) {
        errors.title = "Book title is required!";
      }
    }
    if (formData.selectedRadio === "url") {
      if (!formData.url || formData.url.length === 0) {
        // console.log("url is hadga, ", formData.url);

        errors.url = "Website URL required!";
      }
      if (!isValidHttpUrl(formData.url)) {
        errors.url = "valid website URL required!";
      }
      if (!validateUrl(formData.url)) {
        errors.url = "valid website URL is required!";
      }
    }
    if (formData.selectedRadio === "youtube") {
      if (!matchYoutubeUrl(formData.url)) {
        errors.url = "valid YouTube URL required!";
      }
    }
    if (formData.selectedRadio === "file") {
      if (!fileObject) {
        errors.file = "Please select file!";
      }
    }
    if (formData.selectedRadio === "character") {
      if (!formData.charGreeting && formData.charGreeting.length === 1) {
        errors.charGreeting = "Greeting is required!";
      }
      if (!formData.charOtherInfo && formData.charOtherInfo.length === 1) {
        errors.charOtherInfo = "Other Info is required!";
      }
    }

    return errors;
  };

  const createBook = async (values: any, resetForm: any) => {
    let fileExtension =
      fileObject && fileObject.name ? fileObject.name.split(".")[1] : "";
    let dataToSave = {
      title: values.title,
      image: values.image,
      authors: [values.authors],
      industryIdentifiers: [values.industryIdentifiers],
      email: values.email,
      type:
        values.selectedRadio === "file"
          ? "aws"
          : values.selectedRadio === "url"
          ? "url"
          : values.selectedRadio === "youtube"
          ? "youtube"
          : values.selectedRadio === "character"
          ? "character"
          : "book",
      fileType:
        fileObject && fileObject.type
          ? fileObject.type
          : values.selectedRadio === "url"
          ? "url"
          : values.selectedRadio === "youtube"
          ? "youtube"
          : values.selectedRadio === "character"
          ? "text"
          : fileExtension,
      url: values.url,
      fileName: fileObject ? fileObject.name : "",
      webUrl: values.webUrl,
      charGreeting: values.charGreeting,
      charOtherInfo: values.charOtherInfo,
    };
    setBookTypeName(dataToSave.type);

    let createBookInput;

    if (fileObject) {
      const data = new FormData();

      data.append("files.files", fileObject);
      data.append("data", JSON.stringify(dataToSave));
      createBookInput = data;
    } else {
      createBookInput = dataToSave;
    }

    axios
      .post(
        process.env.REACT_APP_SERVER_URL + "/books/create-book",
        createBookInput
      )
      .then((response) => {
        if (response.data && response.data.success) {
          console.log("response.data.success", response.data);
          trackEvent({
            email: dataToSave.email,
            eventCategory: EventCategory.CHATBOT,
            event: ChatBotEvents.USER_CREATE_CHATBOT_FROM_TRIAL_PAGE,
            subCategory: SubCategory.EVENT,
            source: Source.PUBLIC,
            chatbotType: values.selectedRadio,
            rawData: dataToSave,
            bookId: response.data.data.id,
          });
          if (dataToSave.type !== "book") {
            trackEvent({
              email: dataToSave.email,
              chatbotUserEmail: dataToSave.email,
              eventCategory: EventCategory.CHATBOT,
              event: ChatBotEvents.USER_CREATE_CHATBOT_FROM_TRIAL_PAGE,
              subCategory: SubCategory.EVENT,
              source: Source.PUBLIC,
              chatbotType: values.selectedRadio,
              rawData: response.data,
            });
            if (
              !isTestUser(dataToSave.email) &&
              process.env.REACT_APP_ENV === "production"
            )
              Mixpanel.track("Custom bot created from trial page", {
                email: dataToSave.email,
                bookTitle: dataToSave.title,
                bookAuthors: dataToSave.authors,
                url: values.url,
                fileName: fileObject ? fileObject.name : "",
                type: values.selectedRadio,
              });
          }
          if (
            !isTestUser(dataToSave.email) &&
            process.env.REACT_APP_ENV === "production"
          ) {
            const d = new Date();
            const seconds = Math.round(d.getTime() / 1000);
            _cio.identify({
              // Required attributes
              id: dataToSave.email, // Use either cio_id, id, or email.
              // Strongly recommended attributes
              created_at: seconds, // Timestamp in your system that represents when
              // the user first signed up. You'll want to send it
              // as seconds since the epoch.
              plan: "basic", // To use the example segments, set this to 'free' or 'premium'.
            });
          }

          trackEvent({
            email: dataToSave.email,
            chatbotUserEmail: dataToSave.email,
            eventCategory: EventCategory.CHATBOT,
            event: ChatBotEvents.USER_CREATE_CHATBOT_FROM_TRIAL_PAGE,
            subCategory: SubCategory.EVENT,
            source: Source.PUBLIC,
            chatbotType: values.selectedRadio,
            rawData: response.data,
          });
          if (!isTestUser(dataToSave.email))
            _cio.track(GUEST_USER_CREATED_A_CHATBOT, {
              name: "guest-user-created-a-chatbot",
            });

          resetForm();
          setFileObject(undefined);
          NotificationManager.success(
            response.data.message
              ? response.data.message
              : "Book successfully created!"
          );
          setIndexingProgress(true);
          setShowLoader(false);
          setBookId(response.data.data._id);
          if (response.data.data.indexStatus === 2) {
            setIndexingProgress(false);
            navigate(`/public/books/${response.data.data._id}`);
          }
        } else {
          setShowLoader(false);
          NotificationManager.error(
            response.data.message
              ? response.data.message
              : "Unable to process your request, please try later"
          );
        }
      })

      .catch((error) => {
        // handle error
        resetForm();
        setShowLoader(false);
        setFileObject(undefined);
        NotificationManager.error(parseErrorResponse(error));
        setIndexingProgress(false);
      });
    // _cio.track(USER_ADD_A_BOOK, {
    //   name: "User add a chatbot using create book form",
    // });
  };

  const createUrlBook = async (createBookInput: any, resetForm: any) => {
    // setShowLoader(true);
    axios
      .post(
        process.env.REACT_APP_SERVER_URL + "/books/create-book",
        createBookInput
      )
      .then((response) => {
        // console.log("response is , ", response.data);
        if (response.data && response.data.success) {
          trackEvent({
            email: createBookInput.email,
            chatbotUserEmail: createBookInput.email,
            eventCategory: EventCategory.CHATBOT,
            event: ChatBotEvents.USER_CREATE_CHATBOT_FROM_TRIAL_PAGE,
            subCategory: SubCategory.EVENT,
            source: Source.PUBLIC,
            chatbotType: "url",
            rawData: createBookInput,
            bookId: response.data.data.id,
          });
          if (!isTestUser(createBookInput?.email))
            Mixpanel.track("Custom bot created from trial page", {
              email: createBookInput.email,
              bookTitle: createBookInput.title,
              bookAuthors: createBookInput.authors,
              url: createBookInput.url,
              type: "url",
            });
          if (!isTestUser(createBookInput.email)) {
            const d = new Date();
            const seconds = Math.round(d.getTime() / 1000);
            _cio.identify({
              // Required attributes
              id: createBookInput.email, // Use either cio_id, id, or email.
              // Strongly recommended attributes
              created_at: seconds, // Timestamp in your system that represents when
              // the user first signed up. You'll want to send it
              // as seconds since the epoch.
              plan: "basic", // To use the example segments, set this to 'free' or 'premium'.
            });
            _cio.track(GUEST_USER_CREATED_A_CHATBOT, {
              name: "guest-user-created-a-chatbot",
            });
          }
          // resetForm();
          setFileObject(undefined);
          NotificationManager.success(
            response.data.message
              ? response.data.message
              : "Chatbot successfully created!"
          );
          setIndexingProgress(true);
          setShowLoader(false);
          setBookId(response.data.data._id);
          if (response.data.data.indexStatus === 2) {
            setIndexingProgress(false);
            navigate(`/public/books/${response.data.data._id}`);
          }
        } else {
          setShowLoader(false);
          NotificationManager.error(
            response.data.message
              ? response.data.message
              : "Unable to process your request, please try later"
          );
        }
      })
      .catch((error) => {
        // handle error
        // resetForm();
        setShowLoader(false);
        setFileObject(undefined);
        NotificationManager.error(parseErrorResponse(error));
      });
    if (!isTestUser(createBookInput.email))
      _cio.track(USER_ADD_A_BOOK, {
        name: "User add a book using create book form",
      });
  };

  const [options, setOptions] = useState([]);
  // eslint-disable-next-line
  const [query, setQuery] = useState<any>();
  useEffect(() => {
    if (bookId) {
      socket.emit("bookId", {
        bookId: bookId,
      });
      socket.off("bookId-event");
      socket.on("bookId-event", (data) => {
        if (data) {
          if (data.book) {
            setTimeout(() => {
              setIndexingProgress(false);
              socket.off("bookId-event");
              socket.disconnect();
              navigate(`/public/books/${data.book.id}`);
            }, 60000);
          }
        }
      });
    }
    // eslint-disable-next-line
  }, [bookId]);

  const handleOnSearch = (string: string) => {
    // console.log("string is, ", string);
    setQuery(string);
    // onSearch will have as the first callback parameter
    // the string searched and for the second the results.
    axios
      .post(process.env.REACT_APP_SERVER_URL + "/loaddata", {
        query: string,
        currentPage: 0,
        maxResult: 20,
      })
      .then((response) => {
        // handle success
        if (response.data.success) {
          if (response.data.data.items && response.data.data.items.length > 0) {
            // console.log("response.data.data is, ", response.data.data.items);
            let filteredOptions = response.data.data.items.filter(
              (dt: any) =>
                dt &&
                dt.volumeInfo &&
                dt.volumeInfo.title &&
                dt.volumeInfo.title.length > 0 &&
                dt.volumeInfo.industryIdentifiers &&
                dt.volumeInfo.industryIdentifiers.length > 0 &&
                dt.volumeInfo.authors &&
                dt.volumeInfo.authors.length > 0
            );
            let finalOptions = filteredOptions.map((option: any) => {
              return {
                ...option,
                name: option.volumeInfo.title,
              };
            });
            setOptions(finalOptions);
          }
        } else {
          // NotificationManager.error(response.data.message);
        }
      })
      .catch((error) => {
        NotificationManager.error(parseErrorResponse(error));
      });
  };

  const onInputChange = (event: any) => {
    // console.log("called, ", event);
    if (event.query) {
      handleOnSearch(event.query);
    } else {
      setOptions([]);
    }
  };

  const resetFormikForm = (resetForm: any) => {
    setSelectedOption("");
    setOptions([]);

    resetForm();
  };

  useEffect(() => {
    // Added hubStaff script conditionly
    const script = document.createElement("script");
    script.type = "text/javascript";
    script.id = "hs-script-loader";
    script.async = true;
    script.defer = true;
    script.src = `//js.hs-scripts.com/${process.env.REACT_APP_HUBSPOT_ID}.js`;
    // document.head.appendChild(script);
    var s: any = document.getElementsByTagName("script")[0];
    s.parentNode.insertBefore(script, s);
    return () => {
      // Cleanup: remove the script when the component unmounts
      document.head.removeChild(script);
    };
  }, []);

  return (
    <>
      <Formik
        initialValues={{
          email: "",
          title: "",
          image: "",
          authors: [],
          industryIdentifiers: [],
          file: "",
          selectedRadio: "file",
          url: "",
          webUrl: "",
          charGreeting: "",
          charOtherInfo: "",
        }}
        validate={validateBookSubmission}
        onSubmit={(values, { resetForm }) => {
          if (!isTestUser(values?.email)) Mixpanel.identify(values.email);

          setShowLoader(true);
          if (values.selectedRadio === "url") {
            axios
              .post(process.env.REACT_APP_SERVER_URL + "/books/urls", {
                url: values.url,
              })
              .then((response) => {
                if (response.data && response.data.success) {
                  setShowLoader(false);
                  setUrlList(response.data.data);
                  let dataToSave = {
                    title: values.title,
                    email: values.email,
                    type: "url",
                    fileType: "url",
                    url: values.url,
                    fileName: "",
                    webUrl: values.webUrl,
                  };
                  setCreateBookInput(dataToSave);
                  setResetForm(resetForm);
                  setShowConfirmationBox(true);
                } else {
                  setShowLoader(false);
                  NotificationManager.error(
                    response.data.message
                      ? response.data.message
                      : "Unable to process your request, please try later"
                  );
                }
              })
              .catch((error) => {
                // handle error
                resetForm();
                setShowLoader(false);
                setFileObject(undefined);
                NotificationManager.error(parseErrorResponse(error));
              });
          } else createBook(values, resetForm);
        }}
      >
        {({ values, errors, touched, setFieldValue, resetForm }: any) => (
          <Form
            className="create-trial-chatbot"
            style={{ width: "100%", margin: 0 }}
          >
            <FieldSection>
              <FormLabels>Email Address</FormLabels>
              <FormInput name="email" type="email" />
              {errors.email && touched.email && (
                <FormError className="formError">{errors.email}</FormError>
              )}
            </FieldSection>
            <FieldSection>
              <div id="my-radio-group"></div>
              <div role="group" aria-labelledby="my-radio-group">
                <label style={{ display: "flex", cursor: "pointer" }}>
                  <FieldS type="radio" name="selectedRadio" value="file" />
                  <FormLabelSpan>PDF File</FormLabelSpan>
                </label>
                <MainLabel>
                  <FieldS type="radio" name="selectedRadio" value="url" />
                  <FormLabelSpan>Website URL</FormLabelSpan>
                </MainLabel>
                {/* <MainLabel>
                  <FieldS type="radio" name="selectedRadio" value="youtube" />
                  <FormLabelSpan>YouTube URL</FormLabelSpan>
                </MainLabel> */}
                {process.env.REACT_APP_ENV !== "production" && (
                  <MainLabel>
                    <FieldS
                      type="radio"
                      name="selectedRadio"
                      value="character"
                    />
                    <FormLabelSpan>Character</FormLabelSpan>
                  </MainLabel>
                )}
                {/* <MainLabel>
                  <FieldS type="radio" name="selectedRadio" value="book" />
                  <FormLabelSpan>Book</FormLabelSpan>
                </MainLabel> */}
              </div>
            </FieldSection>
            {values.selectedRadio === "book" ? (
              <>
                <FieldSection style={{ marginBottom: "0" }}>
                  <FormLabels>Book Title</FormLabels>
                </FieldSection>
                <AutoComplete
                  className="autocompleteField"
                  value={selectedOption}
                  field="name"
                  suggestions={options}
                  completeMethod={onInputChange}
                  onChange={(e) => {
                    // console.log("value is, ", typeof e.value);
                    setSelectedOption(e.value);
                    if (
                      e.value &&
                      e.value != null &&
                      typeof e.value === "object"
                    ) {
                      const AUTHORS =
                        e.value.volumeInfo.authors &&
                        e.value.volumeInfo.authors
                          .map((author: any) => author)
                          .join(", ");
                      const ISBN = e.value.volumeInfo.industryIdentifiers
                        .map((isbn: any) => isbn.identifier)
                        .join(", ");
                      setFieldValue("title", e.value.volumeInfo.title);
                      setFieldValue("industryIdentifiers", ISBN);
                      setFieldValue("authors", AUTHORS);
                      setFieldValue(
                        "image",
                        e.value.volumeInfo.imageLinks.thumbnail
                      );
                    } else {
                      setFieldValue("title", "");
                      setFieldValue("industryIdentifiers", "");
                      setFieldValue("authors", "");
                      setFieldValue("image", "");
                    }
                  }}
                />
              </>
            ) : values.selectedRadio === "file" ? (
              <FieldSection>
                <FormLabels>Custom File Title</FormLabels>
                <FormInput name="title" />
                {errors.title && touched.title && (
                  <FormError className="formError">{errors.title}</FormError>
                )}
              </FieldSection>
            ) : values.selectedRadio === "character" ? (
              <FieldSection>
                <FormLabel style={{ fontWeight: "500" }}>Name</FormLabel>
                <FormInput
                  name="title"
                  placeholder="Name your persona / character"
                />
                {errors.title && touched.title && (
                  <FormError className="formError">{errors.title}</FormError>
                )}
              </FieldSection>
            ) : values.selectedRadio === "youtube" ? (
              <FieldSection>
                <FormLabels>Youtube URL Title</FormLabels>
                <FormInput name="title" />
                {errors.title && touched.title && (
                  <FormError className="formError">{errors.title}</FormError>
                )}
              </FieldSection>
            ) : (
              <FieldSection>
                <FormLabels>Website URL Title</FormLabels>
                <FormInput name="title" />
                {errors.title && touched.title && (
                  <FormError className="formError">{errors.title}</FormError>
                )}
              </FieldSection>
            )}

            {values.selectedRadio === "book" && (
              <FieldSection>
                <FormLabels>Book Author</FormLabels>
                <FormInput name="authors" />
                {errors.authors && touched.authors && (
                  <FormError className="formError">{errors.authors}</FormError>
                )}
              </FieldSection>
            )}

            {values.selectedRadio === "file" && (
              <>
                <FieldSection>
                  <Col>
                    <FormLabels>Custom Document/File</FormLabels>
                  </Col>
                  <Col style={{ alignItems: "center" }}>
                    <FormLabelDocInput htmlFor="docFile">
                      Select File
                    </FormLabelDocInput>
                    <FormInput
                      type="file"
                      id="docFile"
                      name="file"
                      accept=".pdf"
                      hidden
                      onChange={(e: any) => {
                        setFileObject(e.target.files[0]);
                      }}
                    />
                    <FormLabel
                      style={{
                        marginLeft: "1rem",
                        fontSize: "14px",
                        fontWeight: "600",
                        letterSpacing: ".8px",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        color: "rgba(51, 48, 60, 0.8)",
                        textOverflow: "ellipsis",
                      }}
                    >
                      {fileObject ? fileObject.name : "No file selected"}
                    </FormLabel>
                    {errors.file && touched.file && (
                      <FormError className="formError">{errors.file}</FormError>
                    )}
                  </Col>
                </FieldSection>
              </>
            )}
            {values.selectedRadio === "url" && (
              <>
                <FieldSection>
                  <FormLabels>Website URL</FormLabels>
                  <FormInput name="url" />
                  {errors.url && touched.url && (
                    <FormError className="formError">{errors.url}</FormError>
                  )}
                </FieldSection>
              </>
            )}
            {values.selectedRadio === "youtube" && (
              <>
                <FieldSection>
                  <FormLabels>YouTube URL</FormLabels>
                  <FormInput name="url" />
                  {errors.url && touched.url && (
                    <FormError className="formError">{errors.url}</FormError>
                  )}
                </FieldSection>
              </>
            )}
            {values.selectedRadio === "character" && (
              <>
                <FormLabel>Greeting</FormLabel>
                <FieldSection>
                  <FormInputTextArea
                    name="charGreeting"
                    placeholder={`What would ${
                      values.title ? values.title : "Character"
                    } say to introduce themselves?`}
                    onChange={(e) => {
                      setFieldValue("charGreeting", e.target.value);
                    }}
                  />
                </FieldSection>
                {errors.charGreeting && touched.charGreeting && (
                  <FormError className="formError">
                    {errors.charGreeting}
                  </FormError>
                )}
                <FormLabel>Other information about character</FormLabel>
                <FieldSection>
                  <FormInputTextArea
                    name="charOtherInfo"
                    placeholder={`Tell us anything else about ${
                      values.title ? values.title : "this character"
                    } `}
                    onChange={(e) => {
                      setFieldValue("charOtherInfo", e.target.value);
                    }}
                  />
                </FieldSection>
                {errors.charOtherInfo && touched.charOtherInfo && (
                  <FormError className="formError">
                    {errors.charOtherInfo}
                  </FormError>
                )}
              </>
            )}
            {values.selectedRadio === "book" && (
              <FieldSection>
                <FormLabels>Book ISBN Number</FormLabels>
                <FormInput name="industryIdentifiers" />
                {errors.industryIdentifiers && touched.industryIdentifiers && (
                  <FormError className="formError">
                    {errors.industryIdentifiers}
                  </FormError>
                )}
              </FieldSection>
            )}
            <Row>
              <Col style={{ marginTop: "1.5rem" }}>
                {!indexingProgress ? (
                  <>
                    <SaveBookButton type="submit">
                      Create Chatbot
                    </SaveBookButton>
                    <CancelBookButton
                      style={{ opacity: 0 }}
                      onClick={() => resetFormikForm(resetForm)}
                    >
                      CANCEL
                    </CancelBookButton>
                  </>
                ) : (
                  <SaveBookButtonDisbaled disabled>
                    Create Chatbot
                  </SaveBookButtonDisbaled>
                )}
              </Col>
            </Row>
          </Form>
        )}
      </Formik>
      <UrlSelectionBox createUrlBook={createUrlBook} isLoggedIn={false} />
    </>
  );
};

export default CreateBook;
